<template>
  <div>
    <form enctype="multipart/form-data" autocomplete="off">
      <v-card>
        <v-toolbar class="indigo darken-4 white--text" dark>
          <v-icon class="mx-4" @click="$router.go(-1)">arrow_back</v-icon>

          <v-toolbar-title
            >{{
              title == "CT"
                ? "Formulario Concepto Técnico"
                : title == "CP"
                ? "Formulario Patente M/N"
                : ""
            }}
            {{
              type_request == "UNO"
                ? "Nuevo Permiso"
                : type_request == "p"
                ? "Prorroga Permiso"
                : type_request == "c"
                ? "Cancelación Permiso"
                : type_request == "v"
                ? "Vinculación M/N"
                : type_request == "d"
                ? "Desvinculación M/N"
                : type_request == "r"
                ? "Reactivación Permiso"
                : type_request == "rm"
                ? "Reemplazo M/N"
                : ""
            }}</v-toolbar-title
          >

          <v-spacer></v-spacer>
        </v-toolbar>

        <v-container grid-list-sm>
          <v-layout row wrap>
            <!-- PRIMER BLOQUE -->
            <v-flex xs12 class="mb-4">
              <v-subheader
                light
                class="subtitle-1 font-italic font-weight-bold"
                >{{
                  title == "CT"
                    ? "CONCEPTO TÉCNICO"
                    : title == "CP"
                    ? "CERTIFICADO DE PATENTE"
                    : ""
                }}</v-subheader
              >
              <v-divider light></v-divider>
            </v-flex>

            <v-flex xs6>
              <v-select
                :readonly="readonly"
                v-model="location"
                prepend-inner-icon="map"
                hint="Lugar de la solicitud"
                :items="items_location"
                item-text="state"
                item-value="abbr"
                label="Lugar de la solicitud"
                persistent-hint
                return-object
                single-line
              ></v-select>
            </v-flex>
            <v-flex xs6>
              <v-menu
                :disabled="readonly"
                v-model="menuExpeditionDate"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="date_expedition"
                    hint="Fecha de expedición"
                    persistent-hint
                    prepend-icon="event"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="date_expedition"
                  @input="menuExpeditionDate = false"
                ></v-date-picker>
              </v-menu>
            </v-flex>
            <v-flex xs12 class="mb-4">
              <v-subheader light class="subtitle-1 font-italic font-weight-bold"
                >DATOS DE LA SOLICITUD</v-subheader
              >
              <v-divider light></v-divider>
            </v-flex>
            <v-flex xs6>
              <v-text-field
                :readonly="readonly"
                v-model="filed"
                prepend-inner-icon="description"
                placeholder="Radicado"
                hint="#Radicado"
                persistent-hint
              ></v-text-field>
            </v-flex>
            <v-flex xs6>
              <v-menu
                :disabled="readonly"
                v-model="menu_date_filing"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="date_filing"
                    hint="Fecha de radicado"
                    persistent-hint
                    prepend-icon="event"
                    readonly
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="date_filing"
                  @input="menu_date_filing = false"
                ></v-date-picker>
              </v-menu>
            </v-flex>
            <v-flex xs6>
              <v-menu
                :disabled="readonly"
                v-model="menu_date_application_assignment"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="date_application_assignment"
                    hint="Fecha Asignación de la solicitud"
                    persistent-hint
                    prepend-icon="event"
                    readonly
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="date_application_assignment"
                  @input="menu_date_application_assignment = false"
                ></v-date-picker>
              </v-menu>
            </v-flex>
            <v-flex xs6 v-if="title == 'CT' && type == 'ind'">
              <v-combobox
                 :readonly="readonly"
                v-model="select_authorized_type"
                :items="items_select_authorized_type"
                hide-selected
                item-text="state"
                item-value="abbr"
                hint="Tipo de autorización - Si el tipo de autorización no está en la lista, escríbalo y de click en ENTER"
                label
                persistent-hint
                small-chips
              ></v-combobox>
            </v-flex>

            <v-flex xs12 class="mb-4">
              <v-subheader light class="subtitle-1 font-italic font-weight-bold"
                >DOCUMENTOS DE LA SOLICITUD</v-subheader
              >
              <v-divider light></v-divider>
            </v-flex>
            <v-flex xs3>
              <p>Carta de solicitud</p>
              <v-radio-group  :readonly="readonly" v-model="radiosApplicationLetter" row>
                <v-radio label="Si" value="SI"></v-radio>
                <v-radio label="No" value="NO"></v-radio>
                <v-radio label="N/A" value="NA"></v-radio>
              </v-radio-group>
            </v-flex>
            <v-flex xs3 v-if="title == 'CT'">
              <p>Cámara de comercio</p>
              <v-radio-group  :readonly="readonly" v-model="radiosChamberOfCommerce" row>
                <v-radio label="Si" value="SI"></v-radio>
                <v-radio label="No" value="NO"></v-radio>
                <v-radio label="N/A" value="NA"></v-radio>
              </v-radio-group>
            </v-flex>
            <v-flex xs3 v-if="title == 'CT'">
              <p>Copia del documento de identificación</p>
              <v-radio-group
                 :readonly="readonly"
                v-model="radiosCopyIdentificationDocument"
                row
              >
                <v-radio label="Si" value="SI"></v-radio>
                <v-radio label="No" value="NO"></v-radio>
                <v-radio label="N/A" value="NA"></v-radio>
              </v-radio-group>
            </v-flex>
            <v-flex xs3 v-if="title == 'CT' && type == 'art'">
              <p>Copia de la ocre</p>
              <v-radio-group  :readonly="readonly" v-model="radiosCopyOCcre" row>
                <v-radio label="Si" value="SI"></v-radio>
                <v-radio label="No" value="NO"></v-radio>
                <v-radio label="N/A" value="NA"></v-radio>
              </v-radio-group>
            </v-flex>
            <v-flex xs3 v-if="title == 'CT'">
              <p>Plan de actividades</p>
              <v-radio-group  :readonly="readonly" v-model="radiosActivityPlan" row>
                <v-radio label="Si" value="SI"></v-radio>
                <v-radio label="No" value="NO"></v-radio>
                <v-radio label="N/A" value="NA"></v-radio>
              </v-radio-group>
            </v-flex>
            <v-flex xs3 v-if="title == 'CT'">
              <p>Fotocopia tarjeta o matrícula profesional</p>
              <v-radio-group
                 :readonly="readonly"
                v-model="radiosCopyProfessionalRegistration"
                row
              >
                <v-radio label="Si" value="SI"></v-radio>
                <v-radio label="No" value="NO"></v-radio>
                <v-radio label="N/A" value="NA"></v-radio>
              </v-radio-group>
            </v-flex>
            <v-flex xs3 v-if="title == 'CT' && type == 'ind'">
              <p>Certificacion empresa que procesara las capturas</p>
              <v-radio-group  :readonly="readonly" v-model="certificado_captura" row>
                <v-radio label="Si" value="SI"></v-radio>
                <v-radio label="No" value="NO"></v-radio>
                <v-radio label="N/A" value="NA"></v-radio>
              </v-radio-group>
            </v-flex>
            <v-flex xs3 v-if="title == 'CT'">
              <p>Copia de la matrícula de la motonave(s)</p>
              <v-radio-group
                 :readonly="readonly"
                v-model="radiosMotorcycleRegistrationCopy"
                row
              >
                <v-radio label="Si" value="SI"></v-radio>
                <v-radio label="No" value="NO"></v-radio>
                <v-radio label="N/A" value="NA"></v-radio>
              </v-radio-group>
            </v-flex>
            <v-flex xs3 v-if="title == 'CT' && type == 'art'">
              <p>Contrato de afiliación de la motonave(s)</p>
              <v-radio-group
                 :readonly="readonly"
                v-model="radiosMotorBoatAffiliationContract"
                row
              >
                <v-radio label="Si" value="SI"></v-radio>
                <v-radio label="No" value="NO"></v-radio>
                <v-radio label="N/A" value="NA"></v-radio>
              </v-radio-group>
            </v-flex>
            <v-flex xs3 v-if="title == 'CT'">
              <p>Infome anual de actividades</p>
              <v-radio-group  :readonly="readonly" v-model="radiosAnnualActivityReport" row>
                <v-radio label="Si" value="SI"></v-radio>
                <v-radio label="No" value="NO"></v-radio>
                <v-radio label="N/A" value="NA"></v-radio>
              </v-radio-group>
            </v-flex>
          </v-layout>
          <v-alert
            width="100%"
            class="mt-3"
            type="error"
            v-for="item in dataInspection"
            :key="item.boat"
            >{{ item.message }}</v-alert
          >
          <ComponentCompany
            v-if="valid_request"
            v-on:childToParentBoats="showData"
            v-on:ValidRequest="validRequest"
            v-on:childData="setData"
            :title="title.toUpperCase()"
            :type="type"
            :type_request="type_request"
            :companyInfo="companyInfo"
            :process="process"
            ref="DataCompany"
            :boat_num="boat_num"
            :consecutive="consecutive"
            :company_id="nit"
          ></ComponentCompany>

          <v-divider></v-divider>

          <v-flex xs12 class="mb-4">
            <v-subheader light class="subtitle-1 font-italic font-weight-bold"
              >ANÁLISIS DE LA SOLICITUD</v-subheader
            >
            <v-divider light></v-divider>
          </v-flex>

          <v-flex xs12 class="mb-4" v-if="valid_request || title == 'CP'">
            <v-subheader>{{
              title == "CT" ? "Concepto y recomendaciones" : "Observaciones"
            }}</v-subheader>
            <template-selector-dialog ref="templateSelectorDialogComponent" @templateSelect="concept_and_recomendations=$event"></template-selector-dialog>
            <ckeditor
               :readonly="readonly"
              :editor="editor"
              v-model="concept_and_recomendations"
              :config="editorConfig"
            ></ckeditor>
          </v-flex>

          <v-flex xs6 v-if="title != 'CP'">
            <v-combobox
              v-model="select_permission_requirements"
              :items="items_select_permission_requirements"
              item-text="state"
              item-value="abbr"
               :readonly="readonly"
              prepend-inner-icon="map"
              hint="¿Cumple con los requisitos?"
              label="¿Cumple con los requisitos?"
              persistent-hint
            ></v-combobox>
          </v-flex>

          <v-flex xs6>
            <v-text-field
              :value="username.firstname + ' ' + username.lastname"
              label="Nombre del funcionario responsable del procedimiento"
              disabled
              persistent-hint
            ></v-text-field>
          </v-flex>

          <v-row>
            <v-col
              cols="6"
              v-if="(type == 'ind' && title == 'CT') || title == 'CP'"
            >
              <v-text-field
                v-model="annual_rate_org"
                :label="
                  title == 'CT'
                    ? 'Valor de tasa anual Total'
                    : 'Valor Derechos Aplicables Total'
                "
                number
                persistent-hint
              ></v-text-field>
            </v-col>
            <v-col
              cols="6"
              v-if="(type == 'ind' && title == 'CT') || title == 'CP'"
            >
              <v-subheader
                light
                class="subtitle-1 font-italic font-weight-bold"
                style="display: flex; justify-content: center"
                >{{
                  title == "CT"
                    ? "Valor de Tasa Anual Referencia: " + annual_rate
                    : "Valor Derechos Aplicables Referencia: " + annual_rate
                }}</v-subheader
              >
            </v-col>
          </v-row>
          <v-flex xs12>
            <v-checkbox
               :readonly="readonly"
              v-model="finalizado"
              label="Solicitud Completada"
              hide-details
              class="shrink mr-2 mt-0"
              value="SI"
            ></v-checkbox>
          </v-flex>
          <v-flex xs12 class="mb-4 mt-5" v-if="readonly">
            <v-subheader
              light
              class="subtitle-1 font-italic font-weight-bold"
              style="display: flex; justify-content: center"
              >RESPUESTA A LA SOLICITUD</v-subheader
            >
            <v-divider light></v-divider>
          </v-flex>
          <v-flex
            xs12
            class="mb-4 mt-5"
            v-if="
              readonly &&
              (select_permission_requirements || {}).abbr == 'UNO'
            "
          >
            <v-file-input
              v-if="type_request != 'pp'"
              v-model="document"
              accept=".pdf"
              placeholder="Seleccione el anexo requerido para aprobar la solicitud"
              label="Resolución escaneada en PDF"
              hint="Peso maximo del documento 20M"
              persistent-hint
            >
              <template v-slot:selection="{ text }">
                <v-chip small label color="primary">{{ text }}</v-chip>
              </template>
            </v-file-input>
          </v-flex>
          <v-row
            v-if="
              (type_request == 'UNO' ||
                type_request == 'p' ||
                type_request == 'r' ||
                type_request == 'pp') &&
              readonly
            "
          >
            <v-col cols="4" v-show="title == 'CT'">
              <v-text-field
                v-model="authorization_number"
                prepend-inner-icon="fa-bars"
                placeholder="Numero de autorización"
                clearable
                hint="Numero de autorización"
                persistent-hint
              ></v-text-field>
            </v-col>

            <v-col cols="4" v-show="title == 'CT'">
              <v-menu
                v-model="menu_date_issue"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="date_issue"
                    label="Fecha de emisión"
                    prepend-icon="event"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="date_issue"
                  @input="menu_date_issue = false"
                ></v-date-picker>
              </v-menu>
            </v-col>

            <v-col cols="4" v-show="title == 'CT'">
              <v-combobox
                v-model="select_issued_by"
                :items="items_select_issued_by"
                hide-selected
                item-text="nombre"
                item-value="codigo"
                hint="Si el campo no está en la lista, escríbalo y de click en ENTER"
                label="Expedido por"
                persistent-hint
                return-object
                small-chips
              ></v-combobox>
            </v-col>
            <v-col cols="4" v-show="title == 'CT'">
              <v-menu
                v-model="menu_date_notification"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="date_notification"
                    hint="Fecha de notificación"
                    prepend-icon="event"
                    readonly
                    persistent-hint
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="date_notification"
                  @input="menu_date_notification = false"
                ></v-date-picker>
              </v-menu>
            </v-col>

            <v-col cols="4" v-show="title == 'CT'">
              <v-menu
                v-model="menu_date_effective_authorization_finish"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                disabled
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="dateFormatted"
                    hint="Fecha de finalización del período de autorización"
                    persistent-hint
                    prepend-icon="event"
                    readonly
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  disabled
                  v-model="dateFormatted"
                  @input="menu_date_effective_authorization_finish = false"
                ></v-date-picker>
              </v-menu>
            </v-col>

            <v-col cols="4" v-show="title == 'CT'">
              <v-select
                v-model="select_fishinng_permission_validity"
                prepend-inner-icon="fa-industry"
                hint="Vigencia del permiso de pesca comercial (años)"
                :items="items_select_fishinng_permission_validity"
                item-text="nombre"
                item-value="codigo"
                label="Vigencia del permiso de pesca comercial (años)"
                persistent-hint
                return-object
                single-line
              ></v-select>
            </v-col>

            <v-col cols="4" sm="12" md="4" v-show="title == 'CP'">
              <v-menu
                v-model="menuExpeditionDatePatent"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="date_expedition_patent"
                    label="Fecha de inicio de vigencia de la patente"
                    prepend-icon="event"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="date_expedition_patent"
                  @input="menuExpeditionDatePatent = false"
                ></v-date-picker>
              </v-menu>
            </v-col>

            <v-col cols="4" sm="12" md="4" v-show="title == 'CP'">
              <v-text-field
                v-model="validity_patent"
                prepend-inner-icon
                hint="Vigencia de la patente (en meses)"
                clearable
                label="Vigencia de la patente (meses)"
                persistent-hint
                type="number"
                @input="PatentV()"
              ></v-text-field>
            </v-col>
            <v-col cols="4" sm="12" md="4" v-show="title == 'CP'">
              <v-menu
                disabled
                v-model="menuExpirationDatePatent"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    readonly
                    v-model="date_expiration_patent"
                    label="Fecha de vencimiento de la patente"
                    prepend-icon="event"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  disabled
                  readonly
                  v-model="date_expiration_patent"
                  @input="menuExpirationDatePatent = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
          <v-flex
            xs12
            v-if="
              (type_request == 'UNO' ||
                type_request == 'p' ||
                type_request == 'r') &&
             readonly
            "
          >
            <v-combobox
              v-model="select_types_of_fishing"
              :items="items_select_types_of_fishing"
              item-text="nombre"
              item-value="codigo"
              label="Tipo de pesquería"
              hint="Si el campo no está en la lista, escríbalo y de click en ENTER"
              persistent-hint
              return-object
              single-line
              multiple
              chips
            >
              <template v-slot:selection="data">
                <v-chip
                  v-bind="data.attrs"
                  :input-value="data.selected"
                  @click="data.select"
                >
                  {{
                    data.item.pivot !== undefined
                      ? data.item.pivot.description
                      : data.item.nombre !== undefined
                      ? data.item.nombre
                      : data.item
                  }}
                </v-chip>
              </template>
            </v-combobox>
          </v-flex>

          <v-flex
            xs12
            v-if="
              (type_request == 'UNO' ||
                type_request == 'p' ||
                type_request == 'r') &&
              readonly
            "
          >
            <v-combobox
              v-model="select_fishing_arts"
              :items="items_select_fishing_arts"
              item-text="nombre"
              item-value="codigo"
              hint="Si el campo no está en la lista, escríbalo y de click en ENTER"
              label="Artes de pesca"
              persistent-hint
              return-object
              single-line
              multiple
              chips
            >
              <template v-slot:selection="data">
                <v-chip
                  v-bind="data.attrs"
                  :input-value="data.selected"
                  @click="data.select"
                >
                  {{
                    data.item.pivot !== undefined
                      ? data.item.pivot.description
                      : data.item.nombre !== undefined
                      ? data.item.nombre
                      : data.item
                  }}
                </v-chip>
              </template>
            </v-combobox>
          </v-flex>
          <v-flex
            xs12
            class="mt-4"
            style="display: flex; justify-content: center"
            v-if="readonly && valid_request == true"
          >
            <v-btn
              color="green darken-1"
              text
              @click="Gestionar('APROBAR')"
              :disabled="type_request != 'pp' && !document"
              >
                <template v-if="status_corr == 6">Finalizar Corrección</template><template v-else>Aprobar</template>
              </v-btn
            >
            <template v-if="status_corr != 6">
              <v-btn
                color="red white-1"
                text
                @click="Gestionar('RECHAZAR')"
                :disabled="type_request != 'pp' && !document"
                >Rechazar</v-btn
              >
            </template>
          </v-flex>
        </v-container>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            @click="saveRequest()"
            class="indigo darken-4 white--text"
            large
            v-if="valid_request && process != 'resolv'"
            >Guardar</v-btn
          >
        </v-card-actions>
      </v-card>
    </form>
  </div>
</template>

<script>
import {
  saveRequestCompanyUrl,
  ResolvRequest,
  getConceptTechnicalArt,
  checkPermit,
  checkBoat,
  getHeader,
} from "../../config.js";

import swal from "sweetalert";

import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

import ComponentCompany from "../../components/ComponentCompany";
import { abreviatureTypeRequest, requestTypes } from "../../enums/requestTypes";
import TemplateSelectorDialog from "@/views/text-template/TemplateSelectorDialog";
import { consultar } from "../../services/variablesconcepto";
export default {
  props: ["type", "process", "nit", "consecutive"],
  components: {
    ComponentCompany,
    TemplateSelectorDialog
  },
  data: () => ({
    status_corr: '',
    title: "",
    type_request: "",
    boat_num: "",
    alertInspection: false,
    dataInspection: [],
    showStateAlertSaveConcept: false,
    showStateAlertConcept: false,
    selectedBoat: "",
    selectedBoats: [],
    authorization_number: "",
    menu_date_notification: false,
    menu_date_effective_authorization_finish: false,
    date_notification: "",
    date_effective_authorization_finish: new Date().toISOString().substr(0, 10),
    date_effective_authorization_init: null,
    editor: ClassicEditor,
    editorData: "",
    editorConfig: {
      toolbar: {
        items: ["bold", "italic", "link", "undo", "redo"],
      },
    },
    location: "",
    filed: "",
    finalizado: "NO",
    document: null,
    select_issued_by: { state: "", abbr: "" },
    items_select_issued_by: [
      { nombre: "1. GOBERNACIÓN SAI", codigo: "UNO" },
      { nombre: "2. AUNAP", codigo: "DOS" },
    ],
    items_location: [
      { state: "1. San Andrés", abbr: "UNO" },
      { state: "2. Providencia y Santa Catalina", abbr: "DOS" },
    ],
    select_permission_requirements: { state: "", abbr: "" },
    items_select_permission_requirements: [
      { state: "1. SI", abbr: "UNO" },
      { state: "2. NO", abbr: "DOS" },
      { state: "3. Requiere información complementaria", abbr: "TRES" },
    ],
    select_type_of_request: null,
    items_select_type_of_request: [
      { state: "1. Nuevo Permiso", abbr: "UNO" },
      { state: "2. Prorroga", abbr: "DOS" },
      { state: "3. Vinculación", abbr: "TRES" },
      { state: "4. Desvinculación", abbr: "CUATRO" },
      { state: "5. Reemplazo motonave", abbr: "CINCO" },
      { state: "6. Cancelación del Permiso", abbr: "SEIS" },
      {
        state: "7. Patente de Pesca (solo en el caso de inspeccion)",
        abbr: "SIETE",
      },
    ],
    select_authorized_type: { state: "", abbr: "" },
    items_select_authorized_type: [
      { state: "1. Pesca Comercial Industrial", abbr: "UNO" },
      { state: "2. Permiso Integrado de Pesca", abbr: "DOS" },
    ],
    dialog: false,
    date_expedition: new Date().toISOString().substr(0, 10),
    menu_date_issue: false,
    date_issue: new Date().toISOString().substr(0, 10),
    date_filing: new Date().toISOString().substr(0, 10),
    date_application_assignment: new Date().toISOString().substr(0, 10),
    menu_date_application_assignment: false,
    menuExpeditionDate: false,
    menu_date_filing: false,
    radiosApplicationLetter: "",
    radiosChamberOfCommerce: "",
    radiosCopyIdentificationDocument: "",
    radiosCopyOCcre: "",
    radiosActivityPlan: "",
    radiosCopyProfessionalRegistration: "",
    radiosMotorcycleRegistrationCopy: "",
    radiosMotorBoatAffiliationContract: "",
    radiosMotorInspection: "SI",
    radiosAnnualActivityReport: "",
    boat_patent: {},
    date_expedition_patent: "",
    date_expiration_patent: "",
    menuExpeditionDatePatent: false,
    menuExpirationDatePatent: false,
    validity_patent: "",
    concept_and_recomendations: "",
    annual_rate: "$ 0",
    annual_rate_org: "$ 0",
    smv: "",
    unlink_boat: null,
    boat_id: null,
    valid_request: true,
    certificado_captura: null,
    select_fishing_arts: [],
    items_select_fishing_arts: [
      { nombre: "1. Sedal o de anzuelo", codigo: "UNO" },
      { nombre: "2. Nasas", codigo: "DOS" },
      { nombre: "3. Buceo", codigo: "TRES" },
    ],
    select_fishinng_permission_validity: { nombre: "", codigo: "" },
    items_select_fishinng_permission_validity: [
      { nombre: "1", codigo: "UNO" },
      { nombre: "2", codigo: "DOS" },
      { nombre: "3", codigo: "TRES" },
      { nombre: "4", codigo: "CUATRO" },
      { nombre: "5", codigo: "CINCO" },
    ],
    select_types_of_fishing: [],
    items_select_types_of_fishing: [
      { nombre: "1. Langosta", codigo: "UNO" },
      { nombre: "2. Pesca blanca", codigo: "DOS" },
      { nombre: "3. Caracol", codigo: "TRES" },
      { nombre: "4. Multiespecífica", codigo: "CUATRO" },
    ],
    boatsInArray: [],
    companyInfo: {},
  }),

  computed: {
    readonly() {
      return this.process === 'resolv';
    },
    username() {
      return JSON.parse(localStorage.getItem("authUser"));
    },
    dateFormatted() {
      if (
        this.select_fishinng_permission_validity.codigo.length > 0 &&
        this.date_notification.length > 0
      ) {
        var d = new Date(this.date_notification);
        var year = d.getFullYear();
        var month = d.getMonth();
        var day = d.getDate();
        var c = new Date(
          year + parseInt(this.select_fishinng_permission_validity.nombre),
          month,
          day
        );
        return c.toISOString().substr(0, 10);
      } else {
        return this.date_effective_authorization_finish;
      }
    },
    canApprove(){
      if (this.type === 'ind') {
        return this.$can('aprobarProcedimientosIndustriales')
      }
      else {
        return this.$can('aprobarProcedimientosArtesanales')
      }
    },
    conceptoItemsSelectFishingArts() {
      return this.$store.state.variablesConcepto;
    }

  },
  watch: {

    conceptoItemsSelectFishingArts(newVal) {
      this.items_select_fishing_arts = newVal.items_select_fishing_arts;
      this.items_select_types_of_fishing = newVal.items_select_types_of_fishing;

    }
  },

  created: async function () {

    const requestType = (requestType) =>
      ({
        UNO: abreviatureTypeRequest.NUEVO,
        DOS: abreviatureTypeRequest.PRORROGA,
        TRES: abreviatureTypeRequest.VINCULACION_EMBARCACION,
        CUATRO: abreviatureTypeRequest.DESVINCULACION_EMBARCACION,
        CINCO: abreviatureTypeRequest.REMPLAZO_EMBARCACION,
        SEIS: abreviatureTypeRequest.CANCELACION_PERMISO,
        SIETE: abreviatureTypeRequest.PATENTE_PESCA,
        OCHO: abreviatureTypeRequest.REACTIVACION_PERMISO,
      }[requestType]);
    if (this.process != "new") {
      this.axios(
        `${getConceptTechnicalArt}?nit=${this.nit}&q=${this.consecutive}`,
        {
          headers: getHeader(),
        }
      ).then((response) => {
        this.setRequest(response.data.data);
        this.title =
          response.data.data.consecutive.includes("CT") === true
            ? "CT"
            : response.data.data.consecutive.includes("PP") === true
            ? "CP"
            : "invalid";
        const request_type = response.data.data.request_type.codigo;
        this.type_request = requestType(request_type);
        this.companyInfo = response.data;

        this.date_expedition_patent = response.data.data.boats[0].date_expedition_patent;
        this.validity_patent = response.data.data.boats[0].validity_of_patent;
        this.date_expiration_patent = response.data.data.boats[0].date_expiration_patent;

      });

    } else if (!this.canApprove && this.process === 'resolv') {

      this.$router.push({name: "not-autorized"})
    }
    
    const concepto = await consultar.fetch();
    this.items_select_fishing_arts = concepto.items_select_fishing_arts;
    this.items_select_types_of_fishing = concepto.items_select_types_of_fishing;
  },
  methods: {

    PatentV() {
      if (this.validity_patent.length <= 0) {
        this.date_expiration_patent = "";
      }
      var d = "";
      d = new Date(this.date_expedition_patent);
      var year = d.getFullYear();
      var month = d.getMonth();
      var day = d.getDate();
      var c = new Date(year, month + parseInt(this.validity_patent), day);
      this.date_expiration_patent = c.toISOString().substr(0, 10);
    },

    setRequest(_request) {
      this.consecutive = _request.consecutive;
      if (_request.location != null)
        this.location = this.items_location.find(
          (x) => x.abbr == _request.location
        );
      this.select_permission_requirements = this.items_select_permission_requirements.find(x => x.abbr ===  _request.cumple_requisitos_solicitud);
      this.certificado_captura = _request.certificado_captura;
      this.radiosActivityPlan = _request.activities_plan;
      this.radiosAnnualActivityReport = _request.annual_activity_report;
      this.radiosChamberOfCommerce = _request.chamber_of_commerce;
      this.radiosCopyIdentificationDocument = _request.identification_copy_doc;
      this.radiosCopyOCcre = _request.ocre_copy;
      this.radiosCopyProfessionalRegistration =
        _request.photocopy_professional_card;
      this.radiosMotorcycleRegistrationCopy =
        _request.motorcycle_enrollment_copy;
      this.radiosMotorBoatAffiliationContract =
        _request.motorcycle_affiliation_cont;
      this.radiosMotorInspection = _request.motorcycle_technical_visit;
      this.radiosApplicationLetter = _request.request_letter;

      this.select_authorized_type = this.items_select_authorized_type.find(
        (x) => x.abbr == _request.type_of_authorization
      );

      this.annual_rate_org = _request.valor_tasa_anual;
      this.filed = _request.filed;
      this.date_expedition = _request.expedition_date;
      this.date_filing = _request.date_filing;
      this.date_application_assignment = _request.date_application_assignment;
      this.concept_and_recomendations = _request.concepto_recomendaciones || '';
      if (_request.estado == 1) this.finalizado = "SI";
      this.status_corr = _request.estado;
    },
    validRequest(boats) {
      if (this.type_request != "c" && this.type_request != "d") {
        if (boats.length > 0) {
          this.valid_request = false;
          this.dataInspection = boats;
        }
      }
    },
    showData(value) {
      this.selectedBoats = value[0];
      if (this.type_request != "pp") this.boat_id = value[1];
      else {
        this.boat_patent = value[1];
        //this.date_expedition_patent = this.boat_patent.date_expedition_patent;
        //this.date_expiration_patent = this.boat_patent.date_expiration_patent;
        //this.validity_patent = this.boat_patent.validity_of_patent;
      }
    },

    setData(value) {

      if (value.company != undefined) {
        this.nit = value.company.nit;
        this.permission_id = value.permission_id;
        this.select_fishinng_permission_validity =
          value.duration_of_permit_granted;

        (this.select_types_of_fishing = value.tipos_pesqueria),
          (this.select_fishing_arts = value.artes_pesca);
      } else if (value.boat != undefined) {
        this.unlink_boat = value.boat;
      } else {
        this.annual_rate = value;
      }
    },
    getRequestType(type) {
      return ({
       [`${abreviatureTypeRequest.NUEVO}`]: requestTypes.NUEVO,
       [`${abreviatureTypeRequest.PRORROGA}`]: requestTypes.PRORROGA,
       [`${abreviatureTypeRequest.VINCULACION_EMBARCACION}`]: requestTypes.VINCULACION_EMBARCACION,
       [`${abreviatureTypeRequest.DESVINCULACION_EMBARCACION}`]: requestTypes.DESVINCULACION_EMBARCACION,
       [`${abreviatureTypeRequest.REMPLAZO_EMBARCACION}`]: requestTypes.REMPLAZO_EMBARCACION,
       [`${abreviatureTypeRequest.CANCELACION_PERMISO}`]: requestTypes.CANCELACION_PERMISO,
       [`${abreviatureTypeRequest.PATENTE_PESCA}`]: requestTypes.PATENTE_PESCA,
       [`${abreviatureTypeRequest.REACTIVACION_PERMISO}`]: requestTypes.REACTIVACION_PERMISO,
      })[type]
    },
    saveRequest() {
      if (this.select_authorized_type != null) {
        if (this.select_authorized_type.abbr == null) {
          var authorized_type = this.select_authorized_type;
          this.select_authorized_type = {
            state: authorized_type,
            abbr: "TRES",
          };
        }
      } else {
        this.select_authorized_type = { state: "", abbr: "" };
      }

      let postData = {
        cumple_requisitos_solicitud: this.select_permission_requirements?.abbr,
        consecutive: this.consecutive,
        location: this.location.abbr,
        filed: this.filed,
        expedition_date: this.date_expedition,
        date_filing: this.date_filing,
        date_application_assignment: this.date_application_assignment,
        type_of_authorization: this.select_authorized_type.abbr,
        request_type: this.getRequestType(this.type_request),
        type: this.type.toUpperCase(),
        request_letter: this.radiosApplicationLetter,
        chamber_of_commerce: this.radiosChamberOfCommerce,
        identification_copy_doc: this.radiosCopyIdentificationDocument,
        ocre_copy: this.radiosCopyOCcre,
        certificado_captura: this.certificado_captura,
        activities_plan: this.radiosActivityPlan,
        photocopy_professional_card: this.radiosCopyProfessionalRegistration,
        motorcycle_enrollment_copy: this.radiosMotorcycleRegistrationCopy,
        motorcycle_affiliation_cont: this.radiosMotorBoatAffiliationContract,
        annual_activity_report: this.radiosAnnualActivityReport,
        motorcycle_technical_visit: this.radiosMotorInspection,
        company_id: this.nit,
        permission_id: this.permission_id,
        boat_id: this.boat_id,
        selectedBoats: this.selectedBoats,
        concepto_recomendaciones: this.concept_and_recomendations,
        valor_tasa_anual: this.annual_rate_org,
        unlink_boat: this.unlink_boat,
        type_corr: this.process,
        nombre_secretario: `${
          this.$store.state.settings.find(
            (x) => x.nombre == "nombre_secretario"
          ).valor
        }`,
        firma_secretario: `${
          this.$store.state.settings.find((x) => x.nombre == "firma_secretario")
            .valor
        }`,
        estado: this.finalizado == "SI" ? 1 : 0,
      };


      this.axios
        .post(saveRequestCompanyUrl, postData, { headers: getHeader() })
        .then(
          (response) => {
            if (response.status === 200) {
              this.showStateAlertSaveConcept = true;
              this.$router.go(-1);
            }
          },
          () => {
            //
            this.showStateAlertSaveConcept = true;
            this.messageStateTechnicalInd =
              "El concepto tecnico Industrial con el consecutivo ingresado ya existe";
          }
        );
    },
    Gestionar(procedimiento) {
      swal("Seguro desea continuar", {
        buttons: {
          cancel: "Cancelar",

          Si: true,
        },
      }).then((value) => {
        switch (value) {
          case "Si":
            var data = new FormData();
            data.append("consecutive", `${this.consecutive}`);
            data.append("type_request", `${this.type_request}`);
            data.append("respuesta", `${procedimiento}`);
            data.append(
              "nombre_secretario",
              `${
                this.$store.state.settings.find(
                  (x) => x.nombre == "nombre_secretario"
                ).valor
              }`
            );
            data.append(
              "firma_secretario",
              `${
                this.$store.state.settings.find(
                  (x) => x.nombre == "firma_secretario"
                ).valor
              }`
            );
            data.append("respuesta", `${procedimiento}`);
            if (this.type_request != "pp") {
              data.append("document", this.document);
            }
            data.append("permission_id", this.permission_id);

            var prom = new Promise((resolve, reject) => {
              if (
                this.type_request == abreviatureTypeRequest.NUEVO ||
                this.type_request == abreviatureTypeRequest.PRORROGA ||
                this.type_request == abreviatureTypeRequest.REACTIVACION_PERMISO
              ) {
                if (this.type_request == abreviatureTypeRequest.NUEVO) {
                  this.date_effective_authorization_init =
                    this.date_notification;
                }

                const obj = {
                  respuesta: procedimiento,
                  permission_id: this.permission_id,
                  consecutive: this.consecutive,
                  type: this.type,
                  date_notification: this.date_notification,
                  effective_activation_date:
                    this.date_effective_authorization_init,
                  effective_inactivation_date: this.dateFormatted,
                  duration_of_permit_granted:
                    this.select_fishinng_permission_validity.codigo,

                  types_of_fishery: this.select_types_of_fishing,
                  date_issue: this.date_issue,
                  issued_by: this.select_issued_by,
                  authorization_number: this.authorization_number,
                  fishing_arts: this.select_fishing_arts,
                };
                this.axios
                  .post(checkPermit, obj, { headers: getHeader() })
                  .then(() => resolve())
                  .catch(() => {
                    reject();
                  });
              } else if (
                this.type_request == abreviatureTypeRequest.PATENTE_PESCA
              ) {
                const obj = {
                  boat_id: this.boat_patent,
                  date_expedition_patent: this.date_expedition_patent,
                  date_expiration_patent: this.date_expiration_patent,
                  validity_of_patent: this.validity_patent,
                };
                this.axios
                  .post(checkBoat, obj, { headers: getHeader() })
                  .then(() => resolve())
                  .catch(() => {
                    reject();
                  });
              } else {
                resolve();
              }
            });

            data.append("authorization_number", this.authorization_number);
            data.append("date_issue", this.date_issue);
            data.append("issued_by", this.select_issued_by.codigo);
            data.append("date_notification", this.date_notification);
            data.append("effective_inactivation_date", this.dateFormatted);
            data.append("status_corr", this.status_corr);
            data.append("duration_of_permit_granted",
                    this.select_fishinng_permission_validity.codigo);

            prom.then(() => {
              this.axios
                .post(ResolvRequest, data, { headers: getHeader() })
                .then(() => {
                  this.$router.go(-1);
                });
            });

            break;

          default:
            return false;
        }
      });
    },
  },

};
</script>

<style lang="scss" scoped></style>
