<template>
  <form enctype="multipart/form-data" autocomplete="off">
    <!-- DATOS DEL PERMISIONARIO (NO MODAL) -->
    <v-layout row wrap>
      <v-flex xs12 class="mb-4">
        <v-subheader light class="subtitle-1 font-italic font-weight-bold">
          DATOS DEL PERMISIONARIO
          <v-spacer></v-spacer>
          <!-- DIALOGO NUEVO PERMISIONARIO-->
          <v-dialog v-model="dialogNewCompany" persistent max-width="850">
            <template v-slot:activator="{ on }">
              <v-btn
                v-if="type_request == 'UNO' && nit == ''"
                small
                color="indigo darken-4 white--text"
                dark
                v-on="on"
              >
                <v-icon small>fa fa-plus</v-icon>Nuevo
              </v-btn>
              <v-btn
                v-else-if="process !== 'resolv' && requestable_type !== requestables.FISHERMEN"
                small
                color="indigo darken-4 white--text"
                dark
                v-on="on"
              >
                <v-icon small>fa fa-edit</v-icon>Modificar
              </v-btn>
            </template>
            <v-card>
              <v-card-title
                v-if="company.length <= 0"
                 class="headline indigo darken-4 white--text justify-space-between"
                primary-title
                >Nuevo Permisionario
                <v-btn class="float-right" icon dark @click="dialogNewCompany = false">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
                </v-card-title
              >
              <v-card-title
                v-else
                 class="headline indigo darken-4 white--text justify-space-between"
                primary-title
                >Editar Permisionario
                <v-btn class="float-right" icon dark @click="dialogNewCompany = false">
                  <v-icon>mdi-close</v-icon>
                </v-btn>  
              </v-card-title
              >
              
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="6">
                      <v-select
                        v-model="select_type_company_modal"
                        prepend-inner-icon="map"
                        :items="items_select_type_company_modal"
                        item-text="state"
                        item-value="abbr"
                        hint="Tipo de empresa "
                        persistent-hint
                        return-object
                        single-line
                        :disabled="type_request != 'UNO' && Boolean(nit)"
                      ></v-select>
                    </v-col>
                    <v-col cols="4">
                      <v-text-field
                        readonly
                        v-model="nit"
                        prepend-inner-icon="business"
                        placeholder="NIT"
                        :disabled="type_request != 'UNO' && nit != ''"
                        hint="NIT"
                        persistent-hint
                        required
                      ></v-text-field>
                    </v-col>
                    <v-col cols="2">
                      <v-text-field
                        readonly
                        v-model="cod_nit"
                        :disabled="type_request != 'UNO' && nit != ''"
                        hint="Dígito de Verificación"
                        persistent-hint
                        required
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field
                        readonly
                        v-model="name_modal"
                        prepend-inner-icon="fa-industry"
                        placeholder="PERMISIONARIO Y/O TITULAR DEL PERMISO"
                        hint="Nombre de la empresa o persona natural"
                        persistent-hint
                        :disabled="type_request != 'UNO' && nit != ''"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="6">
                      <v-text-field
                        v-model="legal_representative_modal"
                        prepend-inner-icon="fa-user"
                        placeholder="Nombre del representante legal"
                        hint="Nombre del representante legal"
                        persistent-hint
                      ></v-text-field>
                    </v-col>
                    <v-col cols="6" v-show="title == 'CT'">
                      <v-combobox
                        v-model="select_id_type_modal"
                        :items="items_select_id_type_modal"
                        hide-selected
                        item-text="state"
                        item-value="abbr"
                        hint="Si el tipo de identificación no está en la lista, escríbalo y de click en ENTER"
                        label="Tipo de identificación"
                        persistent-hint
                        small-chips
                      ></v-combobox>
                    </v-col>
                    <v-col cols="6" v-show="title == 'CT'">
                      <v-text-field
                        v-model="identification_number_modal"
                        prepend-inner-icon="fa-bars"
                        placeholder="Número de identificación"
                        hint="Número de identificación"
                        type="number"
                        persistent-hint
                      ></v-text-field>
                    </v-col>
                    <v-col cols="6">
                      <v-text-field
                        v-model="address_modal"
                        prepend-inner-icon="fa-map-pin"
                        clearable
                        hint="Dirección"
                        label="Como aparece en el RUT o Cámara de Comercio"
                        persistent-hint
                      ></v-text-field>
                    </v-col>
                    <v-col cols="6">
                      <v-text-field
                        v-model="phone_modal"
                        prepend-inner-icon="fa-phone"
                        placeholder="No teléfono"
                        clearable
                        hint="No teléfono"
                        persistent-hint
                      ></v-text-field>
                    </v-col>
                    <v-col cols="6" v-show="title == 'CT'">
                      <v-text-field
                        v-model="email_modal"
                        prepend-inner-icon="fa-at"
                        placeholder="Email"
                        clearable
                        :rules="emailRules"
                        hint="Email"
                        persistent-hint
                      ></v-text-field>
                    </v-col>
                    <v-col cols="6" v-show="title == 'CT' && type == 'ART'">
                      <v-radio-group v-model="radiosFishingCardsModal" row>
                        <v-radio label="Si" value="SI"></v-radio>
                        <v-radio label="No" value="NO"></v-radio>
                        <v-radio label="N/A" value="NA"></v-radio>
                      </v-radio-group>
                      <p>Carné de pesca</p>
                    </v-col>
                    <v-col cols="6" v-show="title == 'CT' && type == 'ART'">
                      <v-text-field
                        v-model="fishingCardNumberModal"
                        prepend-inner-icon="fa-at"
                        placeholder="Número carné de pesca"
                        clearable
                        hint="Número del carné de pesca asignado por la AUNAP."
                        persistent-hint
                      ></v-text-field>
                    </v-col>
                    <v-col cols="6" v-show="title == 'CT' && type == 'art'">
                      <v-radio-group v-model="radiosOrganizationModal" row>
                        <v-radio label="Si" value="SI"></v-radio>
                        <v-radio label="No" value="NO"></v-radio>
                        <v-radio label="N/A" value="NA"></v-radio>
                      </v-radio-group>
                      <p>Organización / Coperativa</p>
                    </v-col>
                    <v-col cols="6" v-show="title == 'CT' && type == 'art'">
                      <v-select
                        v-model="select_organization_name"
                        prepend-inner-icon="fa-industry"
                        hint="Modo de denominacion de una sociedad o grupo de pescadores, según el registro de cámara de comercio"
                        :items="itemsOrganization"
                        item-text="state"
                        item-value="abbr"
                        label="Nombre de organización coperativa"
                        persistent-hint
                        return-object
                        single-line
                      ></v-select>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="indigo darken-4 white--text"
                  dark
                  @click="saveCompany"
                  v-text="company.length <= 0 ? 'Crear' : 'Modificar'"
                ></v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <ComponentModalBoatEdit
            :consecutive="consecutive"
            :title="title"
            :type="type"
            :select_types_of_fishing_2="select_types_of_fishing"
            :dialog="dialogBoatEdit"
            :process="process"
            :type_request="type_request"
            ref="setBoatToChild"
            v-on:handledialogBoat="dialogBoatEdit = false"
            v-on:successUpdate="reloadBoats"
          ></ComponentModalBoatEdit>
        </v-subheader>
        <v-alert v-if="alertNotFoundCompany" type="info" dismissible
          >No se encontro el Permisionario.</v-alert
        >
        <v-divider light></v-divider>
      </v-flex>

      <v-flex xs12 v-if="requestable_type !== requestables.FISHERMEN">

      <v-row>
        <v-col cols="6">
          <v-select
            v-model="select_type_of_company"
            prepend-inner-icon="map"
            hint="Tipo de empresa"
            :items="items_select_type_of_company"
            item-text="state"
            item-value="abbr"
            persistent-hint
            return-object
            single-line
            disabled
          ></v-select>
        </v-col>
        <v-col cols="6">
          <v-text-field
            v-model="name"
            prepend-inner-icon="fa-user"
            hint="Permisionario y/o titular del permiso"
            clearable
            persistent-hint
            disabled
          ></v-text-field>
        </v-col>
        <v-col cols="6">
          <v-text-field
            v-model="nit"
            prepend-inner-icon="business"
            hint="NIT"
            disabled
            persistent-hint
          ></v-text-field>
        </v-col>
        <v-col cols="6">
          <v-text-field
            readonly
            v-model="cod_nit"
            :disabled="type_request != 'UNO' && nit != ''"
            hint="Código de Verificación"
            persistent-hint
            required
          ></v-text-field>
        </v-col>

        <v-col cols="6" v-show="showInput">
          <v-text-field
            v-model="legal_representative"
            prepend-inner-icon="fa-user"
            hint="Nombre del representante legal"
            clearable
            disabled
            persistent-hint
          ></v-text-field>
        </v-col>
        <v-col cols="6" v-show="title == 'CT'">
          <v-select
            v-model="select_type_identification"
            prepend-inner-icon="map"
            hint="Tipo de identificación"
            :items="items_select_type_identification"
            item-text="state"
            item-value="abbr"
            persistent-hint
            return-object
            single-line
            disabled
          ></v-select>
        </v-col>
        <v-col cols="6" v-show="title == 'CT'">
          <v-text-field
            v-model="identification_number"
            prepend-inner-icon="business"
            hint="Número de identificación"
            clearable
            disabled
            persistent-hint
            type="number"
          ></v-text-field>
        </v-col>
        <v-col cols="6" v-if="showInput">
          <v-text-field
            v-model="address"
            prepend-inner-icon="my_location"
            hint="Dirección"
            clearable
            disabled
            persistent-hint
          ></v-text-field>
        </v-col>
        <v-col cols="6">
          <v-text-field
            v-model="phone"
            prepend-inner-icon="phone"
            hint="Teléfono"
            clearable
            persistent-hint
            disabled
          ></v-text-field>
        </v-col>
        <v-col cols="6" v-show="title == 'CT'">
          <v-text-field
            v-model="email"
            prepend-inner-icon="email"
            placeholder="Email"
            clearable
            hint="Email"
            persistent-hint
            disabled
          ></v-text-field>
        </v-col>
      </v-row>
      <v-flex xs6 v-show="title == 'CT' && type == 'ART'"></v-flex>
      <v-flex xs6 v-show="title == 'CT' && type == 'ART'">
        <p>Carné de pesca</p>
        <v-radio-group disabled v-model="radiosFishingCards" row>
          <v-radio label="Si" value="SI"></v-radio>
          <v-radio label="No" value="NO"></v-radio>
          <v-radio label="N/A" value="NA"></v-radio>
        </v-radio-group>
      </v-flex>
      <v-flex xs6 v-show="title == 'CT' && type == 'ART'">
        <v-text-field
          v-model="fishingCardNumber"
          prepend-inner-icon="fa-at"
          placeholder="Número carné de pesca"
          clearable
          disabled
          hint="Número del carné de pesca asignado por la AUNAP."
          persistent-hint
        ></v-text-field>
      </v-flex>
      <v-flex xs6 v-show="title == 'CT' && type == 'art'">
        <v-radio-group
          disabled
          v-model="radiosOrganization"
          row
          hint="Organización / Coperativa"
          persistent-hint
        >
          <v-radio label="Si" value="SI"></v-radio>
          <v-radio label="No" value="NO"></v-radio>
          <v-radio label="N/A" value="NA"></v-radio>
        </v-radio-group>
      </v-flex>

      <v-flex xs6 v-show="title == 'CT' && type == 'art'">
        <v-select
          v-model="select_organization_name"
          prepend-inner-icon="fa-industry"
          hint="Modo de denominacion de una sociedad o grupo de pescadores, según el registro de cámara de comercio"
          :items="itemsOrganization"
          item-text="state"
          item-value="abbr"
          label="Nombre de organización coperativa"
          persistent-hint
          return-object
          disabled
          single-line
        ></v-select>
      </v-flex>
      <br>
      <v-divider></v-divider>
      
      <!-- PERMISO DE PESCA ASOCIADO A LA COMPAÑIA-->
      <v-flex xs12 class="mb-4">
        <v-subheader light class="subtitle-1 font-italic font-weight-bold">
          INFORMACIÓN DEL PRIMER PERMISO DE PESCA
          <v-spacer></v-spacer>

          <!-- DIALOGO NUEVO PERMISO -->
          <v-dialog v-model="dialogNewFirstPermission" persistent max-width="850">
            <template v-slot:activator="{ on }">
              <v-btn
                v-show="
                  type_request == 'UNO' && process != 'resolv'
                "
                small
                color="indigo darken-4 white--text"
                dark
                v-on="on"
              >
                <v-icon small>fa fa-plus</v-icon>Editar
              </v-btn>
            </template>
            <v-card>
              <v-card-title
                v-if="alertPermissionNotFound"
                class="headline indigo darken-4 white--text"
                primary-title
                >Datos del Permiso</v-card-title
              >

              <v-card-title
                v-else
                class="headline indigo darken-4 white--text"
                primary-title
                >Modificar Datos del Permiso</v-card-title
              >
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-flex xs6>
                      <v-menu
                        v-model="menu_date_start_authorization"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                      >
                        <template v-slot:activator="{ on }">
                          <v-text-field
                            v-model="date_start_authorization"
                            label="Fecha inicio autorización"
                            prepend-icon="event"
                            readonly
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="date_start_authorization"
                          @input="menu_date_start_authorization = false"
                        ></v-date-picker>
                      </v-menu>
                    </v-flex>
                    <v-flex xs6>
                      <v-menu
                        v-model="menu_date_finish_authorization"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                      >
                        <template v-slot:activator="{ on }">
                          <v-text-field
                            v-model="date_finish_authorization"
                            label="Fecha fin autorización"
                            persistent-hint
                            prepend-icon="event"
                            readonly
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="date_finish_authorization"
                          @input="menu_date_finish_authorization = false"
                        ></v-date-picker>
                      </v-menu>
                    </v-flex>
                  <v-text-field
                    v-model="n_resolucion"
                    hint="No. Resolución"
                    persistent-hint
                    required
                  ></v-text-field>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="blue darken-1"
                  text
                  @click="dialogNewFirstPermission = false"
                  >Cerrar</v-btn
                >
                <v-btn
                  color="indigo darken-4 white--text"
                  dark
                  @click="saveFirstPermission"
                  >Guardar</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-subheader>
        <v-alert v-if="alertNotFoundPermissionArt" type="info" dismissible
          >No se encontro el permiso de pesca artesanal.</v-alert
        >
      </v-flex>

      <v-divider></v-divider>
      <template>
        <v-row>
          <v-col cols="4">
            <v-menu
              v-model="menu_date_start_authorization_view"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="290px"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  v-model="date_start_authorization_view"
                  hint="Fecha inicio autorización"
                  persistent-hint
                  prepend-icon="event"
                  readonly
                  v-on="on"
                  disabled
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="date_start_authorization"
                @input="menu_date_start_authorization_view = false"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="4">
            <v-menu
              v-model="menu_date_finish_authorization_view"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="290px"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  v-model="date_finish_authorization_view"
                  hint="Fecha fin autorización"
                  persistent-hint
                  prepend-icon="event"
                  readonly
                  v-on="on"
                  disabled
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="date_finish_authorization_view"
                @input="menu_date_finish_authorization_view = false"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="4">
            <v-text-field
              readonly
              v-model="n_resolucion"
              hint="No. Resolución"
              persistent-hint
              required
              disabled
            >
            </v-text-field>
          </v-col>
        </v-row>
      </template>
      <br>
      <!-- PERMISO DE PESCA ASOCIADO A LA COMPAÑIA-->
      <v-divider></v-divider>
      <v-flex xs12 class="mb-4">
        <v-subheader light class="subtitle-1 font-italic font-weight-bold">
          INFORMACIÓN DEL PERMISO DE PESCA
          <v-spacer></v-spacer>

          <!-- DIALOGO NUEVO PERMISO -->
          <v-dialog v-model="dialogNewPermission" persistent max-width="850">
            <template v-slot:activator="{ on }">
              <v-btn
                v-show="
                  (type_request == 'UNO' ||
                    type_request == 'r' ||
                    type_request == 'p') &&
                  process == 'mod'
                "
                small
                color="indigo darken-4 white--text"
                dark
                v-on="on"
              >
                <v-icon small>fa fa-plus</v-icon>Editar
              </v-btn>
            </template>
            <v-card>
              <div>
                <v-card-title
                  v-if="alertPermissionNotFound"
                  class="headline indigo darken-4 white--text justify-space-between"
                  primary-title
                  >Datos del Permiso
                    <v-btn class="float-right" icon dark @click="dialogNewPermission = false">
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                  </v-card-title>


              <v-card-title
                v-else
                class="headline indigo darken-4 white--text justify-space-between"
                primary-title
                >Modificar Datos del Permiso
                  <v-btn class="float-right" icon dark  @click="dialogNewPermission = false">
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </v-card-title
              >              </div>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="6" v-show="title != 'IT'">
                      <v-select
                        v-model="select_marketing_authorization"
                        prepend-inner-icon="fa-industry"
                        hint="Titular de la autorización de comercialización"
                        :items="items_select_marketing_authorization"
                        item-text="nombre"
                        item-value="codigo"
                        label="Titular de la autorización de comercialización"
                        persistent-hint
                        return-object
                        single-line
                      ></v-select>
                    </v-col>

                    <v-col cols="6">
                      <v-combobox
                        v-model="select_authorized_zone"
                        :items="items_select_authorized_zone"
                        item-text="nombre"
                        item-value="codigo"
                        return-object
                        hide-selected
                        hint="Si el campo no está en la lista, escríbalo y de click en ENTER"
                        label="Zona autorizada"
                        persistent-hint
                        small-chips
                      ></v-combobox>
                    </v-col>
                    <v-col cols="6">
                      <v-combobox
                        v-model="select_authorized_landing_port"
                        :items="
                          type == 'art'
                            ? items_select_authorized_landing_port
                            : items_select_authorized_landing_port_ind
                        "
                        item-text="nombre"
                        item-value="codigo"
                        return-object
                        hide-selected
                        hint="Si el campo no está en la lista, escríbalo y de click en ENTER"
                        label="Puerto de desembarque autorizado en Colombia"
                        persistent-hint
                        small-chips
                      ></v-combobox>
                    </v-col>

                    <v-col cols="6">
                      <v-combobox
                        v-model="select_types_of_fishing"
                        :items="$store.state.variablesConcepto.items_select_types_of_fishing"
                        item-text="nombre"
                        item-value="codigo"
                        label="Tipo de pesquería"
                        hint="Si el campo no está en la lista, escríbalo y de click en ENTER"
                        persistent-hint
                        return-object
                        single-line
                        multiple
                        chips
                        :options="options"
                      >
                        <template v-slot:selection="data">
                          <v-chip
                            v-bind="data.attrs"
                            :input-value="data.selected"
                            @click="data.select"
                          >
                            {{
                              data.item.pivot !== undefined
                                ? data.item.pivot.description
                                : data.item.nombre !== undefined
                                ? data.item.nombre
                                : data.item
                            }}
                          </v-chip>
                        </template>
                      </v-combobox>
                    </v-col>

                    <v-col cols="6" v-show="title == 'CT'">
                      <v-autocomplete
                        v-model="select_authorized_species"
                        :items="items_select_authorized_species"
                        :loading="isLoading"
                        :search-input.sync="search"
                        chips
                        clearable
                        hint="Especies autorizadas"
                        persistent-hint
                        hide-selected
                        item-text="nombre"
                        item-value="id"
                        label="Especies autorizadas"
                        return-object
                        single-line
                        multiple
                      >
                        <template v-slot:selection="data">
                          <v-chip
                            v-bind="data.attrs"
                            :input-value="data.selected"
                            @click="data.select"
                          >
                            {{ data.item.codigo }} -
                            {{ data.item.nombre }}
                          </v-chip>
                        </template>
                      </v-autocomplete>
                    </v-col>

                    <v-col cols="6">
                      <v-combobox
                        v-model="select_fishing_arts"
                        :items="items_select_fishing_arts_boat"
                        item-text="nombre"
                        item-value="codigo"
                        hint="Si el campo no está en la lista, escríbalo y de click en ENTER"
                        label="Artes de pesca"
                        persistent-hint
                        return-object
                        single-line
                        multiple
                        chips
                        :options="options"
                      >
                        <template v-slot:selection="data">
                          <v-chip
                            v-bind="data.attrs"
                            :input-value="data.selected"
                            @click="data.select"
                          >
                            {{
                              data.item.pivot !== undefined
                                ? data.item.pivot.description
                                : data.item.nombre !== undefined
                                ? data.item.nombre
                                : data.item
                            }}
                          </v-chip>
                        </template>
                      </v-combobox>
                    </v-col>

                    <v-col cols="6" v-show="title == 'CT'">
                      <v-select
                        v-model="select_authorized_arts"
                        prepend-inner-icon="map"
                        :items="items_select_authorized_arts"
                        item-text="state"
                        item-value="abbr"
                        label="Artes autorizados"
                        hint="Artes autorizados"
                        persistent-hint
                        return-object
                        single-line
                        multiple
                        chips
                        :options="options"
                        v-on:input="limiter"
                      ></v-select>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  v-if="alertNotFoundPermissionArt"
                  color="indigo darken-4 white--text"
                  dark
                  @click="savePermission"
                  >Editar</v-btn
                >
                <v-btn
                  v-else
                  color="indigo darken-4 white--text"
                  dark
                  @click="savePermission"
                  >Guardar</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-subheader>
        <v-alert v-if="alertNotFoundPermissionArt" type="info" dismissible
          >No se encontro el permiso de pesca artesanal.</v-alert
        >

        <v-divider light></v-divider>
      </v-flex>

      <template>
        <v-row>
          <v-col cols="6">
            <v-select
              v-model="select_marketing_authorization"
              prepend-inner-icon="map"
              hint="Titular de la autorización de comercialización"
              :items="items_select_marketing_authorization"
              item-text="nombre"
              item-value="codigo"
              label="Titular de la autorización de comercialización"
              persistent-hint
              return-object
              single-line
              disabled
            ></v-select>
          </v-col>
          <v-col cols="6">
            <v-select
              v-model="select_authorized_zone"
              prepend-inner-icon="map"
              hint="Zona autorizada"
              :items="items_select_authorized_zone"
              item-text="nombre"
              item-value="codigo"
              label="Zona autorizada"
              persistent-hint
              return-object
              single-line
              disabled
            ></v-select>
          </v-col>
          <v-col cols="6">
            <v-combobox
              v-model="select_authorized_landing_port"
              :items="
                type == 'art'
                  ? items_select_authorized_landing_port
                  : items_select_authorized_landing_port_ind
              "
              item-text="nombre"
              item-value="codigo"
              return-object
              single-line
              hint="Si el campo no está en la lista, escríbalo y de click en ENTER"
              label="Puerto de desembarque autorizado en Colombia"
              persistent-hint
              disabled
              small-chips
            ></v-combobox>
          </v-col>
          <v-col cols="6">
            <v-combobox
              v-model="select_types_of_fishing"
              :items="$store.state.variablesConcepto.items_select_types_of_fishing"
              item-text="nombre"
              item-value="codigo"
              label="Tipo de pesquería"
              hint="Si el campo no está en la lista, escríbalo y de click en ENTER"
              persistent-hint
              return-object
              single-line
              multiple
              chips
              disabled
            >
              <template v-slot:selection="data">
                <v-chip
                  v-bind="data.attrs"
                  :input-value="data.selected"
                  @click="data.select"
                  >{{
                    data.item.codigo != "CINCO"
                      ? data.item.nombre
                      : data.item.pivot.description
                  }}</v-chip
                >
              </template>
            </v-combobox>
          </v-col>
          <v-col cols="6" v-show="title == 'CT'">
            <v-select
              v-model="select_authorized_species"
              prepend-inner-icon="map"
              hint="Especies autorizadas"
              :items="items_select_authorized_species"
              item-text="nombre"
              item-value="id"
              label="Especies autorizadas"
              persistent-hint
              return-object
              single-line
              chips
              multiple
              disabled
            ></v-select>
          </v-col>
          <v-col cols="6">
            <v-combobox
              v-model="select_fishing_arts"
              :items="items_select_fishing_arts_boat"
              item-text="nombre"
              item-value="codigo"
              label="Artes de pesca"
              hint="Si el campo no está en la lista, escríbalo y de click en ENTER"
              persistent-hint
              return-object
              single-line
              multiple
              chips
              disabled
            >
              <template v-slot:selection="data">
                <v-chip v-bind="data.attrs" :input-value="data.selected">
                  {{
                    data.item.pivot !== undefined
                      ? data.item.pivot.description
                      : data.item.nombre !== undefined
                      ? data.item.nombre
                      : data.item
                  }}
                </v-chip>
              </template>
            </v-combobox>
          </v-col>
          <v-col cols="6" v-show="title == 'CT'">
            <v-select
              v-model="select_authorized_arts"
              prepend-inner-icon="map"
              hint="Artes autorizados"
              :items="items_select_authorized_arts"
              item-text="state"
              item-value="abbr"
              label="Artes autorizados"
              persistent-hint
              return-object
              single-line
              disabled
              multiple
              :options="options"
              v-on:input="limiter"
            ></v-select>
          </v-col>
          <v-col cols="6" v-if="type_request == 'c'">
            <v-checkbox
              v-model="cancel_permit"
              label="Cancelar Permiso"
              color="indigo"
              value="SI"
              readonly
            ></v-checkbox>
          </v-col>
        </v-row>
      </template>
      </v-flex>
      <v-row class="px-2" v-else>
        <v-col cols="4">
          <v-text-field
            v-model="fisherman.id_type"
            hint="Tipo de documento"
            clearable
            persistent-hint
            disabled
          ></v-text-field>
        </v-col>
        <v-col cols="4">
          <v-text-field
            v-model="fisherman.identification_number"
            prepend-inner-icon="fa-user"
            hint="Número de documento"
            clearable
            persistent-hint
            disabled
          ></v-text-field>
        </v-col>
        <v-col cols="4">
          <v-text-field
            v-model="fisherman.name"
            prepend-inner-icon="fa-user"
            hint="Nombre Completo"
            clearable
            persistent-hint
            disabled
          ></v-text-field>
        </v-col>
        <v-col cols="6">
          <v-text-field
            v-model="fisherman.phone"
            prepend-inner-icon="phone"
            hint="phone"
            clearable
            persistent-hint
            disabled
          ></v-text-field>
        </v-col>
        <v-col cols="6">
          <v-text-field
            v-model="fisherman.organization.abbr_name"
            prepend-inner-icon="business"
            hint="Organización (Nombre abreviado)"
            clearable
            persistent-hint
            disabled
          ></v-text-field>
        </v-col>
      </v-row>
      <v-flex xs12 class="mb-4">
        <v-subheader light class="subtitle-1 font-italic font-weight-bold">
          INFORMACIÓN DE LA EMBARCACIÓN
          <v-spacer></v-spacer>
        </v-subheader>

        <v-alert v-if="alertNotFoundBoatsIn" type="info" dismissible
          >No se encontraron embarcaciones.</v-alert
        >

        <v-alert
          width="100%"
          class="mt-3"
          type="error"
          dismissible
          v-if="showAlertBoat"
        >
          <span v-for="item in messageAlertBoat" :key="item">
            {{ item }}
            <br />
          </span>
        </v-alert>
        <v-divider light></v-divider>
        <template v-if="showBoatsIn">
          <v-card width="100%">
            <v-list subheader two-line flat>
              <v-list-item-group>
                <v-subheader
                  light
                  class="subtitle-2 font-italic font-weight-bold"
                  v-if="boats.filter((x) => x.estado == 1).length > 0"
                  >Embarcaciones Vinculadas</v-subheader
                >
                <v-list-item
                  :key="boat.registration_number"
                  v-for="boat in boats.filter((x) => x.estado == 1)"
                  @click="setBoatEdit(boat)"
                >
                  <template>
                    <v-list-item-action
                      v-if="valid_boat_desvinculacion"
                      @click="emitUnlinkBoat"
                    >
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title>{{
                        boat.boat_name
                      }}</v-list-item-title>
                      <v-list-item-subtitle>{{
                        boat.registration_number
                      }}</v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-icon
                      v-if="
                        type_request == 'pp' ||
                        type_request == 'r' ||
                        type_request == 'p' ||
                        type_request == 'd'
                      "
                    >
                      <v-icon>fa-ship</v-icon>
                      <v-icon small title="Editar" @click="setBoatEdit(boat)"
                        >fa-edit</v-icon
                      >
                    </v-list-item-icon>
                  </template>
                </v-list-item>
              </v-list-item-group>
              <v-list-item-group>
                <v-subheader
                  light
                  class="subtitle-2 font-italic font-weight-bold"
                  v-if="boats.filter((x) => x.estado == 2).length > 0"
                  >Embarcaciones Por Desvincular</v-subheader
                >
                <v-list-item
                  :key="boat.registration_number"
                  v-for="boat in boats.filter((x) => x.estado == 2)"
                  @click="setBoatEdit(boat)"
                >
                  <template>
                    <v-list-item-action
                      v-if="valid_boat_desvinculacion"
                      @click="emitUnlinkBoat"
                    >
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title>{{
                        boat.boat_name
                      }}</v-list-item-title>
                      <v-list-item-subtitle>{{
                        boat.registration_number
                      }}</v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-icon v-if="type_request == 'd'">
                      <v-icon>fa-ship</v-icon>
                      <v-icon small title="Editar" @click="setBoatEdit(boat)"
                        >fa-edit</v-icon
                      >
                    </v-list-item-icon>
                  </template>
                </v-list-item>
              </v-list-item-group>

              <v-list-item-group>
                <v-subheader
                  light
                  class="subtitle-2 font-italic font-weight-bold"
                  v-if="boats.filter((x) => x.estado == 0).length > 0"
                  >Embarcaciones Por Vincular</v-subheader
                >
                <v-list-item
                  :key="boat.registration_number"
                  v-for="boat in boats.filter((x) => x.estado == 0)"
                  @click="setBoatEdit(boat)"
                >
                  <template>
                    <v-list-item-action
                      @click="emitBoats(boat)"
                      v-if="process != 'new'"
                    >
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title>{{
                        boat.boat_name
                      }}</v-list-item-title>
                      <v-list-item-subtitle>{{
                        boat.registration_number
                      }}</v-list-item-subtitle>
                    </v-list-item-content>

                    <v-list-item-icon
                      v-if="
                        type_request == 'UNO' ||
                        type_request == 'v' ||
                        type_request == 'rm' ||
                        type_request == 'r' ||
                        type_request == 'p'
                      "
                    >
                      <v-icon>fa-ship</v-icon>
                      <v-icon small title="Editar" @click="setBoatEdit(boat)"
                        >fa-edit</v-icon
                      >
                    </v-list-item-icon>
                  </template>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-card>
        </template>
        <v-alert
          class="mt-3"
          width="100%"
          type="error"
          dismissible
          v-if="showStateAlertConcept"
          >{{ this.messageStateTechnicalArt }}.</v-alert
        >

        <v-alert
          width="100%"
          class="mt-3"
          type="success"
          dismissible
          v-if="showStateAlertSaveConcept"
          >El concepto tecnico artesanal ha sido guardado.</v-alert
        >
      </v-flex>
      <v-divider></v-divider>

      <v-dialog v-model="dialogBoatUnlink" persistent max-width="700px">
        <v-card>
          <v-card-title
            class="headline indigo darken-4 white--text"
            primary-title
            >Buscar Embarcación</v-card-title
          >
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    v-model="search_boat"
                    label="Numero de matricula o nombre de la embarcacion"
                    @keyup="searchBoat"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-list>
                    <v-list-item-group active-class="border" color="indigo">
                      <v-list-item v-for="(item, i) in boats_unlinked" :key="i">
                        <v-list-item-content>
                          <v-list-item-title
                            v-text="
                              item.registration_number + '-' + item.boat_name
                            "
                            @click="setBoatUnlinked(item)"
                          ></v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list-item-group>
                  </v-list>
                  <span>{{ messageNotFoundBoat }}</span>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="dialogBoatUnlink = false">Cerrar</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-layout>
  </form>
</template>

<script>
import {
  saveBoatUrl,
  saveCompanyUrl,
  savePermissionUrl,
  saveFirstPermissionUrl,
  getBoatsUrl,
  findAuthorizedSpecie,
  getHeader,
  getSMV,
  getConfig,
} from "./../config.js";
import { VMoney } from "v-money";

import ComponentModalBoatEdit from "./ComponentModalBoatEdit";
import { requestTypes } from "../enums/requestTypes.js";
import {getDiffMonth} from "../utils/dateUtils"
import { mapActions, mapGetters } from 'vuex';
import { requestables } from "../enums/requestables";
import {consultar} from "../services/variablesconcepto";
import {OTHER_FISHING_ART_CODE} from "@/constants/other-values.constant";

export default {
  components: {
    ComponentModalBoatEdit,
  },
  props: ["title", "type", "process", "boat_num", "company_id", "consecutive", "isResolv", "companyInfo", "type_request"],
  data: () => ({
    concepto : {},
    date_start_authorization: '',//new Date().toISOString().substr(0, 10),
    date_start_authorization_view: '',
    date_finish_authorization_view: '',
    date_finish_authorization: '',//new Date().toISOString().substr(0, 10),
    menu_date_start_authorization: false,
    menu_date_start_authorization_view: false,
    menu_date_finish_authorization: false,
    menu_date_finish_authorization_view: false,
    cancel_permit: "SI",
    search_boat: "",
    dialogBoatUnlink: false,
    boats_unlinked: "",
    messageNotFoundBoat: "",
    showInput: true,
    inputBoat: true,
    inputBoatIT: false,
    inputBoatCP: false,
    showStateAlertSaveConcept: false,
    showStateAlertConcept: false,
    showAlertBoat: false,
    messageAlertBoat: [],
    messageStateTechnicalArt: "",
    selectedBoat: "",
    selectedBoats: [],
    boat: "",
    boats: [],
    company: [],
    nit: "",
    isLoading: false,
    search: null,
    permittees: [],
    model: 1,
    // Validaciones de los campos //
    nitMask: "##########",
    moneyMask: {
      decimal: ".",
      thousands: ".",
      precision: 0,
      masked: false,
    },
    idMask: "##########",
    emailRules: [
      (v) => !!v || "El E-mail es requerido",
      (v) => /.+@.+/.test(v) || "El E-mail debe ser válido",
    ],
    submitStatus: null,
    authorizedSpecie: "",
    name: "",
    search_nit: "",
    alertNotFoundBoatsIn: false,
    showBoatsIn: false,
    authorization_number: "",
    authorization_number_modal: "",
    dialogNewPermission: false,
    dialogNewFirstPermission: false,
    alertNotFoundCompany: false,
    nit_modal: "",
    cod_nit: "",
    n_resolucion: "",
    name_modal: "",
    legal_representative_modal: "",
    identification_number_modal: "",
    address_modal: "",
    phone_modal: "",
    email_modal: "",
    dialogNewCompany: false,
    identification_number: "",
    address: "",
    phone: "",
    email: "",
    alertNotFoundPermissionArt: false,
    alertPermissionNotFound: true,
    dialogPermisionario: false,
    e1: 1,
    value_vessel: 0,
    dialogBoat: false,
    dialogBoatEdit: false,
    external_marking: "",
    eslora_length: 0,
    extreme_breadth: 0,
    showPermissionIn: false,
    place_issue_registration: "",
    registration_tonnage: 0,
    registration_tonnage_gross: 0,
    select_propulsion_type: { state: "", abbr: "" },
    items_select_propulsion_type: [
      { state: "1. Vela", abbr: "UNO" },
      { state: "2. Remo", abbr: "DOS" },
      { state: "3. Vara", abbr: "TRES" },
      { state: "4. Motor Fuera de Borda", abbr: "CUATRO" },
      { state: "5. Motor Interno", abbr: "CINCO" },
      { state: "6. Otro", abbr: "SEIS" },
      { state: "7. Desconocido", abbr: "SIETE" },
    ],
    main_motor_power: 0,
    number_wineries: 0,
    tall_wineries: 0,
    long_wineries: 0,
    width_wineries: 0,
    capacity_wineries: 0,
    sell_products_to: "",
    vessel_name: "",
    legal_representative: "",
    radios3: "NA",
    radios: "NA",
    radios1: "NA",
    radios2: "NA",
    radios4: "NA",
    radios5: "NA",
    radios6: "NA",
    radios7: "NA",
    radios8: "NA",
    radios9: "NA",
    radiosChart: "NA",
    radiosMagneticCompass: "NA",
    radiosGps: "NA",
    radiosLoran: "NA",
    radiosRadar: "NA",
    radiosEcosonda: "NA",
    radiosRadios: "NA",
    radiosFishAggregatingDevice: "NA",
    radiosApplicationLetter: "NA",
    radiosChamberOfCommerce: "NA",
    radiosCopyIdentificationDocument: "NA",
    radiosCopyOCcre: "NA",
    radiosActivityPlan: "NA",
    radiosCurrentFishingPermit: "SI",
    radiosCopyProfessionalRegistration: "NA",
    radiosCompanyCertification: "NA",
    radiosMotorcycleRegistrationCopy: "NA",
    radiosMotorBoatAffiliationContract: "NA",
    radiosAnnualActivityReport: "NA",
    radiosMotorInspection: "NA",
    radiosFishingCards: "NA",
    radiosFishingCardsModal: "NA",
    fishingCardNumber: "",
    fishingCardNumberModal: "",
    radiosOrganization: "NA",
    radiosOrganizationModal: "NA",
    options: "",
    fishermen_number: 0,
    crew_number: 0,
    fishing_value: 0,
    fishing_zone: "",
    administrator_name: "",
    built_year: "",
    built_country: "",
    boat_photo: null,
    boat_photo_details: null,
    hook_size_type: "",
    hook_quantity: "",
    mother_line_length: "",
    total_number_lines: 0,
    cheating_amount: "",
    number_artifacts: 0,
    components_fad: "",
    quantity_fad: 0,

    items_location: [
      { state: "1. San Andres", abbr: "UNO" },
      { state: "2. Providencia y Santa Cataina", abbr: "DOS" },
    ],

    select_organization_name: { state: "", abbr: "" },
    
    select_issued_by: null,
    items_select_issued_by: [
      { nombre: "1. GOBERNACIÓN SAI", codigo: "UNO" },
      { nombre: "2. AUNAP", codigo: "DOS" },
      /*{ state: "3. OTRO, CUAL?", abbr: "TRES" }*/
    ],
    boat_number: "",
    boat_unique_id: { state: "", abbr: "" },
    items_boat_unique_id: [
      { state: "1. OMI", abbr: "UNO" },
      { state: "2. NIC", abbr: "DOS" },
      { state: "3. NINGUNO", abbr: "TRES" },
    ],

    select_fishing_port: { state: "", abbr: "" },
    items_select_fishing_port: [
      { state: "1. SAI", abbr: "UNO" },
      { state: "2. PROVIDENCIA", abbr: "DOS" },
    ],
    select_marketing_authorization: { nombre: "", codigo: "" },
    items_select_marketing_authorization: [
      { nombre: "EMPRESA", codigo: "UNO" },
      { nombre: "BUQUE(S)", codigo: "DOS" },
      { nombre: "PROPIETARIO DEL BUQUE (S)", codigo: "TRES" },
      { nombre: "OPERADOR / GERENTE DE BUQUES", codigo: "CUATRO" },
      { nombre: "CAPITÁN DEL BARCO", codigo: "CINCO" },
    ],

    select_fishinng_permission_validity: { nombre: "", codigo: "" },
    items_select_fishinng_permission_validity: [
      { nombre: "1", codigo: "UNO" },
      { nombre: "2", codigo: "DOS" },
      { nombre: "3", codigo: "TRES" },
      { nombre: "4", codigo: "CUATRO" },
      { nombre: "5", codigo: "CINCO" },
    ],

    select_authorized_landing_port: { state: "", abbr: "" },
    items_select_authorized_landing_port_ind: [],
    items_select_authorized_landing_port: [],
    select_types_of_fishing: [],
    select_type_fad: { state: "", abbr: "" },
    items_select_type_fad: [
      { state: "FAD Natural", abbr: "1" },
      { state: "FAD Artificial", abbr: "2" },
      { state: "Payao", abbr: "3" },
      { state: "Arrecife artificial", abbr: "4" },
      { state: "Casita cubana", abbr: "5" },
      /*{ state: "Otro, Cual?", abbr: "6" }*/
    ],
    select_artifact_material: { state: "", abbr: "" },
    items_select_artifact_material: [
      { state: "Acero", abbr: "1" },
      { state: "Hierro", abbr: "2" },
      { state: "Madera", abbr: "3" },
      /*{ state: "Otro, Cual?", abbr: "4" }*/
    ],
    select_type_artifact: { state: "", abbr: "" },
    items_select_type_artifact: [
      { state: "Arpones", abbr: "1" },
      { state: "Lanzas", abbr: "2" },
      { state: "Flechas", abbr: "3" },
      { state: "Pinchos", abbr: "4" },
      { state: "Horcas", abbr: "5" },
      { state: "Tenazas", abbr: "6" },
      /*{ state: "Otro, Cual?", abbr: "7" }*/
    ],
    select_main_trap_material: { state: "", abbr: "" },
    items_select_main_trap_material: [
      { state: "Madera y malla metálica", abbr: "1" },
      { state: "Madera y malla plastica", abbr: "2" },
      { state: "Madera y malla en polifilamento", abbr: "3" },
      { state: "Varilla y malla metálica", abbr: "4" },
      { state: "Varilla y malla plastica", abbr: "5" },
      { state: "Varilla y malla en polifilamento", abbr: "6" },
      { state: "Plástico", abbr: "7" },
    ],
    select_denomination_art_fishing: { state: "", abbr: "" },
    items_select_denomination_art_fishing: [
      { state: "Nasas para peces", abbr: "1" },
      { state: "Nasas para crustáceos", abbr: "2" },
      { state: "Nasas para moluscos", abbr: "3" },
    ],
    select_downspout_material: { state: "", abbr: "" },
    items_select_downspout_material: [
      { state: "Monofilamento", abbr: "1" },
      { state: "Polifilamento", abbr: "2" },
      { state: "Acero", abbr: "3" },
      { state: "Polifilamento", abbr: "5" },
    ],
    select_type_hook: { state: "", abbr: "" },
    items_select_type_hook: [
      { state: "Jota", abbr: "1" },
      { state: "Circular", abbr: "2" },
    ],
    select_mother_line_material: { state: "", abbr: "" },
    items_select_mother_line_material: [
      { state: "Monofilamento", abbr: "1" },
      { state: "Multifilamento", abbr: "2" },
    ],
    select_type_line: { state: "", abbr: "" },
    items_select_type_line: [
      { state: "1. Long line", abbr: "1" },
      { state: "2. Espinel", abbr: "2" },
      { state: "3. Palangre de fondo - real", abbr: "3" },
      { state: "4. Palangre de deriva", abbr: "4" },
      { state: "5. Curricanes", abbr: "5" },
      { state: "6. Ballestilla", abbr: "6" },
      { state: "7. Cordel - Línea de mano", abbr: "7" },
    ],
    select_type_conservation_fish: { state: "", abbr: "" },
    items_select_type_conservation_fish: [
      { state: "1. Fresh – Fresco", abbr: "1" },
      { state: "2. Live – Vivo", abbr: "2" },
      { state: "3. Frozen – Congelado", abbr: "3" },
      { state: "98. Other – Otro", abbr: "98" },
      { state: "99. Unknown - Desconocido", abbr: "99" },
    ],
    select_helmet_material: { state: "", abbr: "" },
    items_select_helmet_material: [
      { state: "1. Wood - Madera", abbr: "1" },
      { state: "2. Marine Plywood – Madera forrada", abbr: "2" },
      { state: "3. Aluminium – Aluminio", abbr: "3" },
      { state: "4. Iron/Steel - Hierro / Acero", abbr: "4" },
      { state: "5. Fibreglass – Fibra de vidrio", abbr: "5" },
      { state: "6. Rubber – Caucho", abbr: "6" },
      { state: "7. Cement – Cemento", abbr: "7" },
      { state: "98. Other – Otro", abbr: "98" },
      { state: "99. Unknown - Desconocido", abbr: "99" },
    ],
    select_power_unit: { state: "", abbr: "" },
    items_select_power_unit: [
      { state: "KW. Kilowatt", abbr: "KW" },
      { state: "HP. Horse Power", abbr: "HP" },
      { state: "OT. Other", abbr: "OT" },
    ],
    select_type_identification: { state: "", abbr: "" },
    items_select_type_identification: [
      { state: "1.  CC", abbr: "CC" },
      { state: "2. CE", abbr: "CE" },
      { state: "3. OTRO - CUAL?", abbr: "OTHER" },
    ],
    select_type_of_request: { state: "", abbr: "" },
    items_select_type_of_request: [
      { state: "1. Nuevo Permiso", abbr: "UNO" },
      { state: "2. Prorroga", abbr: "DOS" },
      { state: "3. Vinculación", abbr: "TRES" },
      { state: "4. Desvinculación", abbr: "CUATRO" },
      { state: "5. Reemplazo motonave", abbr: "CINCO" },
      { state: "6. Cancelación del Permiso", abbr: "SEIS" },
      {
        state: "7. Patente de Pesca (solo en el caso de inspeccion)",
        abbr: "SIETE",
      },
    ],
    select: { state: "", abbr: "" },
    items_select: [
      { state: "GOBERNACIÓN SAI", abbr: "GO" },
      { state: "AUNAP", abbr: "AU" },
      { state: "OTRO, CUAL?", abbr: "OT" },
    ],

    select_authorized_zone: { state: "", abbr: "" },
    items_select_authorized_zone: [
      { nombre: "31. Atlantic, Western Central", codigo: "UNO" },
      { nombre: "77. Pacific, Eastern Central", codigo: "DOS" },
      { nombre: "87. Pacific, Southeast ", codigo: "TRES" },
      { nombre: "99. Unknown - Desconocido", codigo: "CINCO" },
    ],

    select_landing_port: { nombre: "", codigo: "" },

    items_select_landing_port: [
      { nombre: "1. Nenes Marine - SAI", codigo: "UNO" },
      { nombre: "2. Gobernación Coral Palace - SAI", codigo: "DOS" },
      { nombre: "3. Almendros - SAI", codigo: "TRES" },
      { nombre: "4. San Luis - Bay (La mansión) - SAI", codigo: "CUATRO" },
      { nombre: "5. San Luis - Bay (Los mellos) - SAI", codigo: "CINCO" },
      { nombre: "6. Elsy Bar- SAI", codigo: "SEIS" },
      { nombre: "7. Cove sea side- SAI", codigo: "SIETE" },
      { nombre: "8. Asomutual - Coopesby pescadero - SAI", codigo: "OCHO" },
      { nombre: "9. Bahia Manzanillo - Providencia", codigo: "NUEVE" },
      { nombre: "10. Bahia sur oeste - Providencia", codigo: "DIEZ" },
      { nombre: "11. Boxon - Providencia", codigo: "ONCE" },
      { nombre: "12. Casa Baja - Providencia", codigo: "DOCE" },
      {
        nombre: "13. Cooperativa Fisher and Farm - Providencia",
        codigo: "TRECE",
      },
      { nombre: "14. Maracaibo - Providencia", codigo: "CATORCE" },
      { nombre: "15. Nelly Downs - Providencia", codigo: "QUINCE" },
      { nombre: "16. Pueblo Viejo - Providencia", codigo: "DIECISEIS" },
      { nombre: "17. Punta Rocosa - Providencia", codigo: "DIECISIETE" },
      { nombre: "18. San Felipe - Providencia", codigo: "DIECIOCHO" },
      { nombre: "19. San Juan - Providencia", codigo: "DIECINUEVE" },
      { nombre: "20. Santa Catalina - Providencia", codigo: "VEINTE" },
      { nombre: "21. Santa Isabel - Providencia", codigo: "VEINTIUNO" },
      { nombre: "22. Otro, Cual?", codigo: "VEINTIDOS" },
    ],

    select_authorized_species: null,
    items_select_authorized_species: [],
    select_vessel_type: { state: "", abbr: "" },
    items_select_vessel_type: [
      { state: "1. TRAWLERS - ARRASTRERO", abbr: "1" },
      { state: "2. SEINERS - CERQUERO", abbr: "2" },
      { state: "3. DREDGERS - RASTRAS", abbr: "3" },
      { state: "4. LIFT NETTERS – REDES IZADAS", abbr: "4" },
      { state: "5. GILLNETTERS - BARCO AGALLERO", abbr: "5" },
      { state: "6. TRAP SETTERS – BARCOS CON TRAMPAS", abbr: "6" },
      { state: "7. LINERS – BARCOS DE LINEA", abbr: "7" },
      {
        state: "8. VESSELS USING PUMPS FOR FISHING - BARCO CON BOMBAS",
        abbr: "8",
      },
      { state: "9. MULTIPURPOSE VESSELS - POLIVALENTES", abbr: "9" },
      {
        state: "10. RECREATIONAL FISHING VESSELS - RECREACIONES",
        abbr: "10",
      },
      { state: "49. FISHING VESSELS NOT SPECIFIED", abbr: "11" },
    ],
    select_registration_port: { state: "", abbr: "" },
    select_authorized_type: { state: "", abbr: "" },
    items_select_authorized_type: [
      { state: "1. Pesca Comercial Industrial", abbr: "UNO" },
      { state: "2. Permiso Integrado de Pesca", abbr: "DOS" },
    ],
    select_fishing_arts: [],
    select_fishing_arts_boat: { state: "", abbr: "" },
    select_type_of_company: { state: "", abbr: "" },
    items_select_type_of_company: [
      { state: "1.  NATURAL", abbr: "UNO" },
      { state: "2. JURÍDICA", abbr: "DOS" },
    ],
    select_authorized_arts: [],
    select_type_company_modal: { state: "", abbr: "" },
    items_select_type_company_modal: [
      { state: "Natural", abbr: "UNO" },
      { state: "Jurídica", abbr: "DOS" },
    ],
    select_id_type_modal: { state: "", abbr: "" },
    items_select_id_type_modal: [
      { state: "Cédula de ciudadanía", abbr: "CC" },
      { state: "Cédula de extranjería", abbr: "CE" },
    ],
    validity_patent: "",
    applicable_values: "",
    manager_operator: "",
    maritime_agent: "",

    visible: false,
    date_expedition: new Date().toISOString().substr(0, 10),
    date_issue: new Date().toISOString().substr(0, 10),
    date_start_period: new Date().toISOString().substr(0, 10),
    date_end_period: new Date().toISOString().substr(0, 10),
    date_notification: "",
    date_filing: new Date().toISOString().substr(0, 10),
    date_assignment: new Date().toISOString().substr(0, 10),
    date_issue_registration: new Date().toISOString().substr(0, 10),
    date_expiration_registration: new Date().toISOString().substr(0, 10),
    date_application_assignment: new Date().toISOString().substr(0, 10),
    date_issue_modal: new Date().toISOString().substr(0, 10),
    date_notification_modal: new Date().toISOString().substr(0, 10),
    date_expedition_patent: new Date().toISOString().substr(0, 10),
    date_expiration_patent: new Date().toISOString().substr(0, 10),
    date_effective_authorization_init_modal: new Date()
      .toISOString()
      .substr(0, 10),
    date_effective_authorization_finish_modal: new Date()
      .toISOString()
      .substr(0, 10),

    date_effective_authorization_init: new Date().toISOString().substr(0, 10),
    date_effective_authorization_finish: new Date().toISOString().substr(0, 10),
    date_of_issue: new Date().toISOString().substr(0, 10),
    menu_date_issue_modal: false,
    menu_date_issue: false,
    menu_date_notification_modal: false,
    menu: false,
    menu2: false,
    menu3: false,
    menu4: false,
    menu7: false,
    menu8: false,
    menu9: false,
    menu_start_date_period: false,
    menu_end_date_period: false,
    menu_date_effective_authorization_init: false,
    menu_date_effective_authorization_finish: false,
    menu_date_effective_authorization_init_modal: false,
    menu_date_effective_authorization_finish_modal: false,
    menuExpeditionDate: false,
    menu_date_issue_registration: false,
    menu_date_expiration_registration: false,
    menu_date_filing: false,
    menu_date_application_assignment: false,
    menu_date_of_issue: false,
    menu_date_notification: false,
    menuExpeditionDatePatent: false,
    menuExpirationDatePatent: false,
    menuDateValidityPatent: false,

    concept_and_recomendations: "",
    servant_name: "",
    valid_company: true,
    valid_permit: true,
    valid_boat: true,
    valid_boat_desvinculacion: false,
    unlinkBoats: [],
    permission_id: null,

    smv: "",
    estado: "",
    fisherman : {},
    requestable_type : '',
    authorized_arts: [],
  }),
  directives: { money: VMoney },
  computed: {
    ...mapGetters(['allOrganizations']),
    nitErrors() {
      const errors = [];
      if (!this.$v.nit_modal.$dirty) return errors;
      !this.$v.nit_modal.required && errors.push("El nit es requerido");
      return errors;
    },
    itemsOrganization() {
      return this.allOrganizations.map(x => ({abbr: x.id, state: x.abbr_name}));
    },
    requestables(){
      return requestables;
    },
    items_select_authorized_arts(){
      return this.$store.state.variablesConcepto.items_select_authorized_arts?.map(x => {
        return {
          state : x.nombre,
          abbr : x.codigo
        }
    });
    },
    items_select_fishing_arts_boat() {
     return this.$store.state.variablesConcepto.items_select_fishing_arts_boat?.filter(art => {
       return art.codigo !== OTHER_FISHING_ART_CODE;
     });
    }
  },
  watch: {
    itemsOrganization() {
     this.select_organization_name = this.itemsOrganization.find(x => x.abbr == this.select_organization_name);
    },
     companyInfo: function (value) {
      this.setCompany(value)
    },
    items_select_authorized_arts() {
       this.authorized_arts.forEach((el) => {
        let arte = this.items_select_authorized_arts.find(
          (x) => x.abbr === el
        );
        if (arte != undefined) this.select_authorized_arts.push(arte);
      });
    }
  },

  async created() {    
    this.axios.get(getConfig, { headers: getHeader() }).then((response) => {
      this.$store.commit("SETTINGS", response.data);
      this.settings = response.data;
    });
    if (this.type == "ind" && this.title == "CP") {
      this.showInput = false;
    }
    if (this.title != "ind" || (this.title == "CP" && this.type == "IND")) {
      this.inputBoat = false;
    }
    if (this.type == "ind" && this.title == "IT") {
      this.inputBoatIT = true;
    }
    if (this.type == "ind" && this.title == "IT") {
      this.inputBoatCP = true;
    }
    if (this.items_select_authorized_species.length > 0) return;
    this.loading = true;
    this.axios
      .get(findAuthorizedSpecie)
      .then((response) => {
        this.items_select_authorized_species = response.data;
      })

      .then(() => {
        this.loading = false;
      });

    this.axios
      .get(getSMV, { headers: getHeader() })
      .then((response) => (this.smv = response.data[0].valor));

    if (
      this.process == "resolv" ||
      this.type_request == requestTypes.PRORROGA ||
      this.type_request == requestTypes.CANCELACION_PERMISO
    ) {
      this.valid_boat = false;
    }
    if (this.type === "art" && !this.allOrganizations.length) {
       await this.getOrganizations();
    }
    this.concepto = await consultar.fetch();
    this.items_select_authorized_landing_port = this.$store.state.variablesConcepto.items_select_authorized_landing_port;
    this.items_select_authorized_landing_port_ind = this.$store.state.variablesConcepto.items_select_authorized_landing_port;
  },
  methods: {
    ...mapActions(['getOrganizations']),
    setBoatEdit: function (boat) {
      this.dialogBoatEdit = true;
      this.$refs.setBoatToChild.setBoat(boat);
    },

    emitBoats(boat) {
      let tasa;
      this.messageAlertBoat = [];
      if (
        this.type == "ind" &&
        this.title == "CT" &&
        this.type_request != "c"
      ) {
        let value_boat = 0;
        let foreing_boats = 0;
        let local_boats = 0;

        this.boats.map((value) => {
          if (value.estado < 2) {
            if (value.current_state_pavilion != null) {
              if (value.current_state_pavilion == "COL") {
                value_boat = value_boat != 75 ? 60 : 75;
                local_boats += 1;
              } else {
                value_boat = value_boat != 60 ? 75 : 60;
                foreing_boats += 1;
              }
            } else {
              this.showAlertBoat = true;
              this.messageAlertBoat.push(
                "La embarcación " +
                  value.boat_name +
                  " No tiene un Estado actual del pabellón - BANDERA registrado"
              );
              if (this.messageAlertBoat.length > 0) {
                setTimeout(() => {
                  this.showAlertBoat = false;
                  this.messageAlertBoat = [];
                }, 5000);
              }
            }
          }
        });

        tasa =
          (value_boat +
            (local_boats * 15 - (value_boat == 60 ? 15 : 0)) +
            (foreing_boats * 25 - (value_boat == 75 ? 25 : 0))) *
          this.smv;

        this.$emit("childData", "$ " + tasa);
      }
      if (this.title == "CP") {
        if (this.selectedBoats.length > 1) {
          this.selectedBoats.pop();
        }
        if (this.type == "ind") {
          let tipo_pesca;

          if (
            this.select_types_of_fishing.find((x) => x.codigo == "DOS") !=
              undefined &&
            this.select_types_of_fishing.length == 1
          ) {
            tipo_pesca = "DOS";
          } else {
            tipo_pesca = "CUATRO";
          }

          let nsmv = 0; //numero de salario minimo vigente

          if (tipo_pesca == "DOS" && boat.current_state_pavilion == "COL") {
            nsmv = 2;
          } else if (
            tipo_pesca == "DOS" &&
            boat.current_state_pavilion != "COL"
          ) {
            nsmv = 4;
          } else if (
            tipo_pesca != "DOS" &&
            boat.current_state_pavilion == "COL"
          ) {
            nsmv = 3;
          } else if (
            tipo_pesca != "DOS" &&
            boat.current_state_pavilion != "COL"
          ) {
            nsmv = 5;
          }

          if (this.select_types_of_fishing.length == 0) {
            this.selectedBoats.pop();
            this.showAlertBoat = true;
            this.messageAlertBoat.push(
              "El tipo de pesca en el permiso no puede estar vacio"
            );
          }
          if (boat.current_state_pavilion == null) {
            this.selectedBoats.pop();
            this.showAlertBoat = true;
            this.messageAlertBoat.push(
              "La embarcación " +
                boat.boat_name +
                " No tiene un Estado actual del pabellón - BANDERA registrado"
            );
          }
          if (
            boat.net_registered_tonnage.length == 0 ||
            boat.net_registered_tonnage == null ||
            boat.net_registered_tonnage == 0
          ) {
            this.selectedBoats.pop();
            this.showAlertBoat = true;
            this.messageAlertBoat.push(
              "La embarcación " +
                boat.boat_name +
                " No tiene TONELAJE DE REGISTRO NETO registrado o es cero"
            );
          }
          if (boat.validity_of_patent == null || boat.validity_of_patent == 0) {
            this.selectedBoats.pop();
            this.showAlertBoat = true;
            this.messageAlertBoat.push(
              "La embarcación " +
                boat.boat_name +
                " No tiene VIGENCIA DE LA PATENTE registrada"
            );
          }
          setTimeout(() => {
            this.showAlertBoat = false;
            this.messageAlertBoat = [];
          }, 5000);

          let tasa =
            ((parseInt(nsmv) *
              parseInt(this.smv) *
              parseFloat(boat.net_registered_tonnage)) /
              parseInt(12)) *
            parseInt(boat.validity_of_patent);

          this.$emit(
            "childData",
            "$ " + Intl.NumberFormat().format(Math.round(tasa))
          );
        } else {
          if (boat.net_registered_tonnage >= 3) {
            let tasa =
              ((parseInt(1) *
                parseInt(this.smv) *
                parseFloat(boat.net_registered_tonnage)) /
                parseInt(12)) *
              parseInt(boat.validity_of_patent);

            this.$emit(
              "childData",
              "$ " + Intl.NumberFormat().format(Math.round(tasa))
            );
          }
        }
      }
    },
    saveCompany() {
      if (this.select_id_type_modal != null) {
        if (this.select_id_type_modal.abbr == null) {
          var authorized_type = this.select_id_type_modal;
          this.select_id_type_modal = { state: authorized_type, abbr: "OTHER" };
        }
      } else {
        this.select_id_type_modal = { state: "", abbr: "" };
      }

      const postData = {
        process: this.title,
        consecutive: this.consecutive,
        type_of_company: this.select_type_company_modal?.abbr,
        type_company: this.type,
        nit: this.nit,
        name: this.name_modal,
        legal_representative: this.legal_representative_modal,
        id_type: this.select_id_type_modal.abbr,
        identification_number: this.identification_number_modal,
        address: this.address_modal,
        phone: this.phone_modal,
        email: this.email_modal,
        fishing_card: this.radiosFishingCardsModal,
        fishing_card_number: this.fishingCardNumberModal,
        organization: this.radiosOrganizationModal,
        organization_name: this.select_organization_name?.abbr || this.select_organization_name,
      };

      this.axios
        .post(saveCompanyUrl, postData, { headers: getHeader() })
        .then((response) => {
          this.dialogNewCompany = false;
          if (response.status == 200) {
            this.setCompany(response.data.message);
          }
        }).catch(()=> {
          this.select_organization_name = null;
        });
    },
    setCompany: async function (data) {
      const company = data?.data?.company || data;
      const permission = data?.data?.permission;

      this.dialogPermisionario = false;
      this.select_type_of_company = company.type_of_company;
      this.select_type_company_modal =
        this.items_select_type_company_modal.find(
          (x) => x.abbr == company.type_of_company
        );
      this.nit = company.nit;
      this.cod_nit = company.cod_nit;

      this.name = company.name;
      this.name_modal = company.name;
      this.legal_representative = company.legal_representative;
      this.legal_representative_modal = company.legal_representative;
      this.select_type_identification = company.id_type;
      if (this.select_id_type_modal == null) {
        this.select_id_type_modal = { state: "", abbr: "" };
      }
      this.select_id_type_modal = this.items_select_id_type_modal.find(
        (x) => x.abbr == company.id_type
      );
      this.identification_number = company.identification_number;
      this.identification_number_modal = company.identification_number;
      this.address = company.address;
      this.address_modal = company.address;
      this.phone = company.phone;
      this.phone_modal = company.phone;
      this.email = company.email;
      this.email_modal = company.email;
      this.radiosFishingCards = company.fishing_card === 1 ? "SI" : "NO";
      this.fishingCardNumber = company.fishing_card_number;
      this.radiosOrganization = company.organization === 1 ? "SI" : "NO";
      this.select_organization_name = Number(company.organization_name)
      this.company = company;
      if (permission) {
          await this.setPermission(permission);
       }
      if(data.first_permission != null)
      {
        this.setFirstPermission(data.first_permission);
      }
      if ( data?.data?.requestable_type) {
        this.fisherman = data.data.requestable_type === requestables.FISHERMEN ? data.data.requestable : {};
        this.requestable_type = data.data.requestable_type;
      }
      this.reloadBoats();
    },

    async setPermission(permission) {
       this.items_select_authorized_landing_port = this.$store.state.variablesConcepto.items_select_authorized_landing_port;
      this.items_select_authorized_landing_port_ind = this.$store.state.variablesConcepto.items_select_authorized_landing_port;
      if (permission == null) return false;
      this.date_effective_authorization_init = new Date(
        permission.effective_activation_date
      )
        .toISOString()
        .substr(0, 10);
      this.date_effective_authorization_finish = new Date(
        permission.effective_inactivation_date
      )
        .toISOString()
        .substr(0, 10);

      if (permission.duration_of_permit_granted != null)
        this.select_fishinng_permission_validity =
          this.items_select_fishinng_permission_validity.find(
            (x) => x.codigo == permission.duration_of_permit_granted
          );
      this.date_issue = permission.date_of_issue;
      this.date_notification = permission.date_of_notification;
      this.select_authorized_zone = permission.zona_autorizada;
      this.authorized_arts = permission.authorized_arts.split(",");
      this.estado = permission.estado;

      if (permission.landing_zone != null)
      {
        this.select_authorized_landing_port =
          this.type == "art"
            ? this.items_select_authorized_landing_port.find(
                (x) => x.codigo == permission.landing_zone
              )
            : this.items_select_authorized_landing_port_ind.find(
                (x) => x.codigo == permission.landing_zone
              );
      }
      if (this.select_authorized_landing_port == undefined) {
        permission.landing_zone;
      }

      this.authorization_number = permission.authorization_number;
      this.permission_id = permission.id;
      this.select_marketing_authorization = permission.titular_autorizacion;
      this.select_authorized_species = permission.especies_autorizadas;
      this.select_types_of_fishing = permission.type_fishery;
      this.select_fishing_arts = permission.fishing_arts;
      this.showPermissionIn = true;
      this.alertPermissionNotFound = false;

      this.permittees = [];
      this.$emit("childData", {
        company: this.company,
        permission_id: permission.id,
        tipos_pesqueria: this.select_types_of_fishing,
        artes_pesca: this.select_fishing_arts,
        duration_of_permit_granted: this.select_fishinng_permission_validity,
      });
    },

    setFirstPermission(permission) {
      if (permission == null) return false;
      //this.date_start_authorization = permission.authorization_init_date;
      //this.date_finish_authorization = permission.authorization_end_date;
      this.n_resolucion = permission.resolution_number;

      this.date_start_authorization_view = new Date(
        permission.authorization_init_date
      )
      .toISOString()
      .substr(0, 10);

      this.date_finish_authorization_view = new Date(
        permission.authorization_end_date
      )
        .toISOString()
        .substr(0, 10);
    },

    savePermission() {
      if (this.select_authorized_arts == null) {
        this.select_authorized_arts = [];
      }
      var authorized_artstemp = [];
      for (var i = 0; i < this.select_authorized_arts.length; i++) {
        authorized_artstemp.push(this.select_authorized_arts[i].abbr);
      }

      const postData = {
        consecutive: this.consecutive,
        permission_id: this.permission_id,
        estado: this.estado,
        authorization_number: this.authorization_number,
        date_of_issue: this.date_issue,
        date_of_notification: this.date_notification,
        issued_by: this.select_issued_by,
        authorization_holder: this.select_marketing_authorization,
        effective_activation_date: this.date_effective_authorization_init,
        effective_inactivation_date: this.date_effective_authorization_finish,
        duration_of_permit_granted:
          this.select_fishinng_permission_validity.codigo,
        authorized_zone: this.select_authorized_zone,
        landing_zone: this.select_authorized_landing_port,
        types_of_fishery: this.select_types_of_fishing,
        authorized_species: this.select_authorized_species,
        fishing_arts: this.select_fishing_arts,
        authorized_arts: authorized_artstemp,
        company_id: this.nit,
        type: this.type.toUpperCase(),
      };

      this.axios
        .post(savePermissionUrl, postData, { headers: getHeader() })
        .then(() => {
          this.dialogNewPermission = false;
        })
    },

    saveFirstPermission() {
      const postData = {
        'authorization_init_date': this.date_start_authorization,
        'authorization_end_date': this.date_finish_authorization,
        'resolution_number': this.n_resolucion,
        'company_nit': this.nit,
        'type': this.type,
        'consecutive': this.consecutive,
      };

      this.axios
        .post(saveFirstPermissionUrl, postData, { headers: getHeader() })
        .then((response) => {
          this.setFirstPermission(response.data);
          this.dialogNewFirstPermission = false;
        })
    },

    saveBoat: async function () {
      this.dialogBoat = false;

      if (!this.nit) {
        this.showStateAlertConcept = true;
        this.messageStateTechnicalArt =
          "Debe primero seleccionar o crear un Permisionario, antes de crear una embarcación";
        setTimeout(() => {
          this.showStateAlertConcept = false;
        }, 4000);
        return true;
      }

      let postData = {
        company_id: this.nit,
        consecutive: this.consecutive,
        boat_unique_id: this.boat_unique_id.abbr,
        boat_unique_id_state: this.boat_number,
        registration_number: this.external_marking,
        expedition_place: this.place_issue_registration,
        expedition_date: this.date_issue_registration,
        expiration_date: this.date_expiration_registration,
        current_state_pavilion: this.select_current_state_pavilion.abbr,
        boat_name: this.vessel_name,
        registration_port: this.select_registration_port.abbr,
        registration_port_state: this.select_registration_port.state,
        boat_type: this.select_vessel_type.abbr,
        boat_type_state: this.select_vessel_type.state,
        approx_boat_value: this.value_vessel,
        fishing_port: this.select_fishing_port.abbr,
        sell_products_to: this.sell_products_to,
        length_overall: this.eslora_length,
        beam_extreme_breadth: this.extreme_breadth,
        net_registered_tonnage: this.registration_tonnage,
        gross_tonnage: this.registration_tonnage_gross,
        type_of_propulsion: this.select_propulsion_type.abbr,
        main_engines_power: this.main_motor_power,
        power_unit: this.select_power_unit.abbr,
        hull_material: this.select_helmet_material.abbr,
        warehouses_number: this.number_wineries,
        fish_hold_high: this.tall_wineries,
        fish_hold_long: this.long_wineries,
        fish_hold_width: this.width_wineries,
        fish_hold_capacity: this.capacity_wineries,
        fish_hold_type: this.select_type_conservation_fish.abbr,
        beneficial_owner_name: this.administrator_name,
        built_year: this.built_year,
        built_country: this.built_country,
        boat_photo: this.boat_photo,
        boat_photo_details: this.boat_photo_details,
        number_of_crew: this.crew_number,
        manager_operator: this.manager_operator,
        maritime_agent: this.maritime_agent,
        number_of_fishermens: this.fishermen_number,
        fishing_arts: this.select_fishing_arts_boat.abbr,
        fishing_arts_value: this.fishing_value,
        fishing_zone: this.fishing_zone,
        fishing_line: this.select_type_line.abbr,
        fishing_line_state: this.select_type_line.state,
        hook_type: this.select_type_hook.abbr,
        hook_type_state: this.select_type_hook.state,
        hook_size: this.hook_size_type,
        hooks_number: this.hook_quantity,
        mother_line_length: this.mother_line_length,
        mother_line_material: this.select_mother_line_material.abbr,
        mother_line_material_state: this.select_mother_line_material.state,
        downpipe_material: this.select_downspout_material.abbr,
        downpipe_material_state: this.select_downspout_material.state,
        total_lines_number: this.total_number_lines,
        art_type_traps: this.select_denomination_art_fishing.abbr,
        art_type_traps_state: this.select_denomination_art_fishing.state,
        type_traps_number: this.cheating_amount,
        type_traps_material: this.select_main_trap_material.abbr,
        type_traps_material_state: this.select_main_trap_material.state,
        type_wound_artifacts: this.select_type_artifact.abbr,
        type_wound_artifacts_state: this.select_type_artifact.state,
        amount_artifacts: this.number_artifacts,
        artifacts_material: this.select_artifact_material.abbr,
        artifacts_material_state: this.select_artifact_material.state,
        aggregating_devices: this.radiosFishAggregatingDevice,
        type_fad: this.select_type_fad.abbr,
        type_fad_state: this.select_type_fad.state,
        fad_amount: this.quantity_fad,
        fad_components: this.components_fad,
        radiosChart: this.navigation_letters,
        radiosMagneticCompass: this.magnetic_compass,
        radiosGps: this.gps,
        radiosLoran: this.loran,
        radiosRadar: this.radar,
        radiosEcosonda: this.eco_probe,
        radiosRadios: this.communication_radios,
        //nuevos patente
        current_fishing_permit: this.radiosCurrentFishingPermit,
        date_expedition_patent: this.date_expedition_patent,
        date_expiration_patent: this.date_expiration_patent,
        validity_of_patent: this.validity_patent,
        applicable_values: this.applicable_values,
        // type_boat: this.type
      };

      await this.axios
        .post(saveBoatUrl, postData, { headers: getHeader() })
        .then(() => {
          this.resetBoat();
        });
    },

    reloadBoats: async function () {
      await this.axios
        .get(getBoatsUrl + "/" + this.consecutive + "?nit=" + this.company_id, {
          headers: getHeader(),
        })
        .then((response) => {
          if (response.status === 200) {
            //NO HAY DATOS
            if (!Array.isArray(response.data) && response.data.length > 0) {
              this.alertNotFoundBoatsIn = true;

              setTimeout(() => {
                this.alertNotFoundBoatsIn = false;
              }, 2000);

              return true;
            } else {
              this.boats = response.data;

              this.showBoatsIn = true;

              const botes_por_vincular = this.boats.filter(
                (x) => x.estado == 0
              );
              botes_por_vincular.forEach((x) => {
                this.selectedBoats.push(x.id);
              });

              this.$emit("childToParentBoats", [
                this.selectedBoats,
                this.boats[0].id,
              ]);

              if (this.type_request == requestTypes.PATENTE_PESCA) {
                const boat_patent = this.boats.find(
                  (x) => x.id == this.boat_num
                );
                if (boat_patent != undefined) {
                  this.boats = [];
                  this.boats.push(boat_patent);
                  this.selectedBoats.push(this.boat_num);
                  this.$emit("childToParentBoats", [
                    this.selectedBoats,
                    boat_patent,
                  ]);
                  this.emitBoats(boat_patent);
                  this.valid_boat = false;
                }
              }

              if (
                this.type_request != requestTypes.DESVINCULACION_EMBARCACION &&
                this.type_request != requestTypes.CANCELACION_PERMISO
              ) {
                let boats = [];
                this.boats.forEach((x) => {
                  if (x.estado <= 1) {
                    if (x.expedition_inspection == null) {
                      boats.push({
                        boat: x.original_boat_id,
                        message: `La embarcación  ${x.boat_name} - ${x.registration_number} no posee una inspección tecnica`,
                      });
                    } else {
                      let today = new Date();
                      let exp = new Date(x.expedition_inspection);
                      const diff = getDiffMonth(today, exp)
                      if (diff >= 12) {
                        boats.push({
                          boat: x.original_boat_id,
                          message: `La embarcación ${x.boat_name} - ${x.registration_number} no posee una inspección tecnica vigente`,
                        });
                      }
                    }
                  }
                });
                // this.$emit("ValidRequest", boats);
              }

              if (this.title == "CT") {
                this.emitBoats();
              }
            }
          }
        });

      if (
        this.type_request == requestTypes.VINCULACION_EMBARCACION &&
        this.boats.filter((z) => z.estado == 0).length == 1
      ) {
        this.valid_boat = false;
      }
      if (this.type_request == requestTypes.DESVINCULACION_EMBARCACION) {
        this.valid_boat_desvinculacion = true;
        this.valid_boat = false;
        const unlinkBoat = this.boats.find((x) => x.estado == 2);
        if (unlinkBoat != undefined) {
          this.unlinkBoats.push(unlinkBoat.registration_number);
          this.$emit("childData", {
            boat: this.unlinkBoats[0],
          });
        }
      }
    },
    limiter(e) {
      if (e.length > 3) {
        e.pop();
      }
    },
    resetBoat() {
      this.e1 = 1;
      this.boat_unique_id = { state: "", abbr: "" };
      this.external_marking = "";
      this.place_issue_registration = "";
      this.date_issue_registration = "";
      this.date_expiration_registration = "";
      this.select_current_state_pavilion = {
        state: "",
        abbr: "",
      };
      this.vessel_name = "";
      this.select_registration_port = { state: "", abbr: "" };
      this.select_vessel_type = { state: "", abbr: "" };
      this.value_vessel = "";
      this.select_fishing_port = { state: "", abbr: "" };
      this.sell_products_to = "";
      this.eslora_length = "";
      this.extreme_breadth = "";
      this.registration_tonnage = "";
      this.registration_tonnage_gross = "";
      this.select_propulsion_type = { state: "", abbr: "" };
      this.main_motor_power = "";
      this.select_power_unit = { state: "", abbr: "" };
      this.select_helmet_material = { state: "", abbr: "" };
      this.number_wineries = "";
      this.tall_wineries = "";
      this.long_wineries = "";
      this.width_wineries = "";
      this.capacity_wineries = "";
      this.select_type_conservation_fish = {
        state: "",
        abbr: "",
      };
      this.administrator_name = "";
      this.built_year = "";
      this.boat_photo = null;
      this.boat_photo_details = null;
      this.crew_number = "";
      this.fishermen_number = "";
      this.select_fishing_arts_boat = { state: "", abbr: "" };
      this.fishing_value = "";
      this.fishing_zone = "";
      this.select_type_line = { state: "", abbr: "" };
      this.select_type_hook = { state: "", abbr: "" };
      this.hook_size_type = "";
      this.hook_quantity = "";
      this.mother_line_length = "";
      this.select_mother_line_material = { state: "", abbr: "" };
      this.select_downspout_material = { state: "", abbr: "" };
      this.total_number_lines = "";
      this.select_denomination_art_fishing = {
        state: "",
        abbr: "",
      };
      this.cheating_amount = "";
      this.select_main_trap_material = { state: "", abbr: "" };
      this.select_type_artifact = { state: "", abbr: "" };
      this.number_artifacts = "";
      this.select_artifact_material = { state: "", abbr: "" };
      this.radiosFishAggregatingDevice = "";
      this.select_type_fad = { state: "", abbr: "" };
      this.quantity_fad = "";
      this.components_fad = "";
      this.navigation_letters = "";
      this.magnetic_compass = "";
      this.gps = "";
      this.loran = "";
      this.radar = "";
      this.eco_probe = "";
      this.communication_radios = "";
      this.radiosCurrentFishingPermit = "SI";
      this.date_expedition_patent = "";
      this.date_expiration_patent = "";
      this.validity_patent = "";
      this.applicable_value = "";
    },
    searchBoat() {
      this.messageNotFoundBoat = "";

      let unlinked = this.$store.getters.UnlinkedBoats.filter(
        (boat) =>
          (boat.registration_number.includes(this.search_boat.toUpperCase()) ||
            boat.boat_name.includes(this.search_boat.toUpperCase())) &&
          boat.type_boat == this.type.toUpperCase()
      );
      this.boats_unlinked = unlinked.slice(0, 15);
      if (this.boats_unlinked.length == 0)
        this.messageNotFoundBoat =
          " No se encontró ninguna embarcación sin vincular";
    },
    setBoatUnlinked(item) {
      if (
        this.type_request == requestTypes.VINCULACION_EMBARCACION &&
        this.boats.filter((z) => z.estado == 0).length == 0
      ) {
        this.valid_boat = false;
      }

      let boat = Object.assign({}, item);
      boat.company_id = this.nit;
      this.boats.push(boat);

      this.selectedBoats.push(boat.id);
      this.dialogBoatUnlink = false;
      this.emitBoats(boat);
      this.search_boat = "";
      this.boats_unlinked = [];
    },

    emitUnlinkBoat() {
      if (this.unlinkBoats.length > 1) {
        this.unlinkBoats.pop();
      }
      this.$emit("childData", {
        boat: this.unlinkBoats[0],
      });
    },
  },
};
</script>
