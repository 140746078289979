<template>
  <div>
    <v-dialog :disabled="only" v-model="dialog" persistent max-width="1500px">
      <v-card>
        <v-card-title
          class="headline indigo darken-4 white--text justify-space-between"
          primary-title
          >Editar Embarcación {{ boat_name }}
          <v-btn
            class="float-right"
            icon
            dark
            @click="$emit('handledialogBoat', false)"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-stepper :disabled="only" v-model="e1" class="ma-4">
            <v-stepper-header>
              <v-stepper-step :complete="e1 > 1" step="1"
                >IDENTIFICACIÓN</v-stepper-step
              >
              <v-divider></v-divider>
              <v-stepper-step :complete="e1 > 2" step="2"
                >DATOS DE REGISTRO</v-stepper-step
              >
              <v-divider></v-divider>
              <v-stepper-step :complete="e1 > 3" step="3"
                >DIMENSIONES</v-stepper-step
              >
              <v-divider></v-divider>
              <v-stepper-step v-if="title == 'CP'" :complete="e1 > 4" step="4"
                >PATENTE DE PESCA</v-stepper-step
              >
              <v-divider v-if="title == 'CP'"></v-divider>
              <v-stepper-step
                :complete="title == 'CT' ? e1 > 4 : e1 > 5"
                :step="title == 'CT' ? '4' : '5'"
                >ARTES DE LA PESCA</v-stepper-step
              >
              <v-stepper-step :complete="e1 > 6" v-if="title == 'IT'" step="6"
                >AYUDAS A LA NAVEGACION</v-stepper-step
              >
              <v-divider></v-divider>
            </v-stepper-header>
            <v-stepper-items>
              <v-stepper-content step="1">
                <v-card class="mb-12" color="white lighten-1">
                  <v-card-text>
                    <v-container>
                      <v-row>
                        <v-col cols="4">
                          <v-text-field
                            :readonly="external_marking != null"
                            v-model="external_marking"
                            hint="Marcador Externo - Número de Matrícula*"
                            persistent-hint
                            required
                          ></v-text-field>
                        </v-col>
                        <v-col cols="4" v-show="title == 'CT'">
                          <v-combobox
                            :readonly="only || concept_only"
                            v-model="boat_unique_id"
                            :items="items_boat_unique_id"
                            hide-selected
                            item-text="state"
                            item-value="abbr"
                            hint="identificación Embarcación"
                            persistent-hint
                            small-chips
                          ></v-combobox>
                        </v-col>
                        <v-col
                          cols="4"
                          v-show="
                            title == 'CT' &&
                            boat_unique_id.abbr != 'TRES' &&
                            boat_unique_id.abbr != ''
                          "
                        >
                          <v-text-field
                            :readonly="only || concept_only"
                            v-model="boat_number"
                            label="Identificador único del Buque (UVI) /Numero OMI"
                            hint="N°"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="4">
                          <v-text-field
                            readonly
                            v-model="vessel_name"
                            label="Nombre de la embarcación"
                            hint="Nombre de la embarcación"
                            persistent-hint
                          ></v-text-field>
                        </v-col>
                        <v-col cols="4">
                          <v-text-field
                            :readonly="only || concept_only"
                            v-model="place_issue_registration"
                            label="Lugar de expedición de la matrícula"
                            hint="Lugar de expedición de la matrícula"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="4">
                          <v-text-field
                            :readonly="only || concept_only"
                            v-model="expedition_by"
                            label="Matrícula Expedida por"
                            hint="Matrícula Expedida por"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="4" v-if="title == 'CT'">
                          <v-menu
                            :readonly="only || concept_only"
                            v-model="menu_date_issue_registration"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            offset-y
                            min-width="290px"
                          >
                            <template v-slot:activator="{ on }">
                              <v-text-field
                                :disabled="only || concept_only"
                                v-model="date_issue_registration"
                                label="Fecha de expedición de la matrícula"
                                prepend-icon="event"
                                readonly
                                v-on="on"
                              ></v-text-field>
                            </template>
                            <v-date-picker
                              :readonly="only || concept_only"
                              v-model="date_issue_registration"
                              @input="menu_date_issue_registration = false"
                            ></v-date-picker>
                          </v-menu>
                        </v-col>
                        <v-col cols="4" v-if="title == 'CT'">
                          <v-menu
                            :readonly="only || concept_only"
                            v-model="menu_date_expiration_registration"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            offset-y
                            min-width="290px"
                          >
                            <template v-slot:activator="{ on }">
                              <v-text-field
                                :readonly="only || concept_only"
                                v-model="date_expiration_registration"
                                label="Fecha de vencimiento de la matrícula"
                                prepend-icon="event"
                                v-on="on"
                              ></v-text-field>
                            </template>
                            <v-date-picker
                              :readonly="only || concept_only"
                              v-model="date_expiration_registration"
                              @input="menu_date_expiration_registration = false"
                            ></v-date-picker>
                          </v-menu>
                        </v-col>
                      </v-row>
                    </v-container>
                    <small>* Indicador de campo requerido</small>
                  </v-card-text>
                </v-card>

                <p align="right">
                  <v-btn
                    color="primary"
                    :readonly="!external_marking"
                    @click="e1 = 2"
                    >Continuar</v-btn
                  >
                </p>
              </v-stepper-content>
              <v-stepper-content step="2">
                <v-card class="mb-12" color="white lighten-1">
                  <v-card-text>
                    <v-container>
                      <v-alert v-if="alertNotFound" type="info" dismissible
                        >No se encontro este registro en SAI por favor agreguelo
                        como un nuevo registro</v-alert
                      >

                      <v-row>
                        <v-col cols="4">
                          <v-combobox
                            v-model="select_type_document"
                            :items="items_select_type_document"
                            hide-selected
                            item-text="state"
                            item-value="abbr"
                            hint="Tipo de Documento de Identificación del Propietario"
                            persistent-hint
                            small-chips
                          ></v-combobox>
                        </v-col>
                        <v-col cols="4">
                          <v-text-field
                            v-model="identification_number"
                            placeholder="Identificación del Propietario"
                            hint="Presione la tecla ENTER para consultar el propietario en el sistema"
                            persistent-hint
                            required
                            @keypress.enter="Search()"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="4">
                          <v-text-field
                            v-model="owner_name"
                            placeholder="Nombres del Propietario"
                            hint="Nombres del Propietario"
                            persistent-hint
                            required
                            :disabled="nfound"
                          ></v-text-field>
                        </v-col>

                        <v-col cols="12">
                          <v-select
                            v-model="select_current_state_pavilion"
                            prepend-inner-icon="map"
                            hint="Estado actual del pabellón"
                            :items="items_select_current_state_pavilion"
                            item-text="state"
                            item-value="abbr"
                            label="Estado actual del pabellón - BANDERA"
                            persistent-hint
                            return-object
                            single-line
                          ></v-select>
                        </v-col>

                        <v-col cols="6" sm="12" md="6">
                          <v-combobox
                            :readonly="only || concept_only"
                            v-model="select_registration_port"
                            :items="$store.state.variablesConcepto.items_select_registration_port"
                            hide-selected
                            item-text="state"
                            item-value="abbr"
                            hint="Si el campo no está en la lista, escríbalo y de click en ENTER"
                            label="Puerto de registro"
                            persistent-hint
                            small-chips
                          ></v-combobox>
                        </v-col>
                        <v-col cols="6" sm="12" md="6" v-if="type == 'art'">
                          <v-combobox
                            :readonly="only || concept_only"
                            v-model="select_authorized_landing_port"
                            :items="$store.state.variablesConcepto.items_select_authorized_landing_port"
                            hide-selected
                            item-text="nombre"
                            item-value="codigo"
                            hint="Si el campo no está en la lista, escríbalo y de click en ENTER"
                            label="Puerto de Desembarque"
                            persistent-hint
                            small-chips
                          ></v-combobox>
                        </v-col>
                        <v-col cols="6" sm="12" md="6">
                          <v-combobox
                            :readonly="only || concept_only"
                            v-model="select_vessel_type"
                            prepend-inner-icon="map"
                            :items="items_select_vessel_type"
                            item-text="state"
                            item-value="abbr"
                            label="Tipo de barco"
                            hint="Tipo de barco"
                            persistent-hint
                            return-object
                            chips
                            single-line
                          ></v-combobox>
                        </v-col>
                        <v-col cols="6" sm="12" md="6" v-show="title == 'IT'">
                          <v-text-field
                            :readonly="only || concept_only"
                            v-model.lazy="value_vessel"
                            prepend-inner-icon="attach_money"
                            placeholder="Valor aproximado de la embarcación"
                            hint="Valor aproximado de la embarcación"
                            persistent-hint
                            v-money="moneyMask"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" md="12" v-show="title != 'CP'">
                          <v-text-field
                            :readonly="only || concept_only"
                            v-model="sell_products_to"
                            label="Venderá los productos a"
                            hint="Venderá los productos a"
                            persistent-hint
                          ></v-text-field>
                        </v-col>
                        <v-col cols="6" md="6" sm="6" v-show="title == 'CT'">
                          <v-select
                            type="number"
                            :readonly="only || concept_only"
                            v-model="built_year"
                            prepend-inner-icon="map"
                            :items="items_select_years()"
                            item-text="value"
                            item-value="id"
                            label="Año de construcción"
                            hint="Año de construcción"
                            persistent-hint
                            return-object
                            chips
                            single-line
                          ></v-select>
                        </v-col>
                        <v-col cols="6" md="6" sm="6" v-show="title == 'CT'">
                          <v-select
                            :readonly="only || concept_only"
                            v-model="built_country"
                            prepend-inner-icon="map"
                            hint="País de construcción"
                            :items="items_select_current_state_pavilion"
                            item-text="state"
                            item-value="abbr"
                            label="País de construcción"
                            persistent-hint
                            return-object
                            single-line
                          ></v-select>
                        </v-col>
                      </v-row>
                    </v-container>
                    <small>* Indicador de campo requerido</small>
                  </v-card-text>
                </v-card>
                <div class="input-group">
                  <p align="left" style="position: absolute">
                    <v-btn text @click="e1 = 1">Regresar</v-btn>
                  </p>
                  <p align="right">
                    <v-btn color="primary" @click="e1 = 3">Continuar</v-btn>
                  </p>
                </div>
              </v-stepper-content>
              <v-stepper-content step="3">
                <v-card class="mb-12" color="white lighten-1">
                  <v-card-text>
                    <v-container>
                      <v-row>
                        <v-col cols="4" md="4" sm="12">
                          <v-text-field
                            :readonly="only || concept_only"
                            v-model="eslora_length"
                            prepend-inner-icon
                            placeholder="ESLORA - Longitud total (LOA)(m)"
                            hint="ESLORA - Longitud total (LOA)(m)"
                            persistent-hint
                            suffix="m"
                            type="number"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="4" sm="12" md="4">
                          <v-text-field
                            :readonly="only || concept_only"
                            v-model="calado"
                            prepend-inner-icon
                            placeholder="Calado/Proyecto (m)"
                            hint="Calado/Proyecto (m)"
                            persistent-hint
                            suffix="m"
                            type="number"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="4" md="4" sm="12">
                          <v-text-field
                            :readonly="only || concept_only"
                            v-model="extreme_breadth"
                            prepend-inner-icon
                            placeholder="Manga / extrema amplitud (m)"
                            hint="Manga / extrema amplitud (m)"
                            suffix="m"
                            persistent-hint
                            type="number"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="4" sm="12" md="4">
                          <v-text-field
                            v-model="registration_tonnage"
                            prepend-inner-icon
                            placeholder="Tonelaje de Registro Neto (TRN)"
                            hint="Tonelaje de Registro Neto (TRN)"
                            suffix="TRN"
                            persistent-hint
                            type="number"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="4" sm="12" md="4">
                          <v-text-field
                            :readonly="only || concept_only"
                            v-model="registration_tonnage_gross"
                            prepend-inner-icon
                            placeholder="Tonelaje de Registro Bruto (TRB)"
                            hint="Tonelaje de Registro Bruto (TRB)"
                            suffix="TRB"
                            persistent-hint
                            type="number"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="4" sm="12" md="4" v-if="type == 'art'">
                          <v-select
                            :readonly="only || concept_only"
                            v-model="select_propulsion_type"
                            prepend-inner-icon="map"
                            :items="items_select_propulsion_type"
                            item-text="state"
                            item-value="abbr"
                            label="Tipo de propulsión"
                            hint="Elementos o equipos que permiten desplazar la embarcación (motor, remos, vela, etc.)."
                            return-object
                            single-line
                          ></v-select>
                        </v-col>
                        <v-col cols="4" sm="12" md="4">
                          <v-text-field
                            :readonly="only || concept_only"
                            v-model="main_motor_power"
                            prepend-inner-icon
                            placeholder="Potencia del motor principal/s"
                            hint="Potencia del motor principal/s"
                            persistent-hint
                            type="number"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="4" sm="12" md="4">
                          <v-combobox
                            :readonly="only || concept_only"
                            v-model="select_power_unit"
                            prepend-inner-icon="map"
                            hint="Unidad de Potencia"
                            :items="items_select_power_unit"
                            item-text="state"
                            item-value="abbr"
                            label="Unidad de Potencia"
                            persistent-hint
                            return-object
                            chips
                            single-line
                          ></v-combobox>
                        </v-col>
                        <v-col cols="4" sm="12" md="4" v-show="title != 'CT'">
                          <v-select
                            :readonly="only || concept_only"
                            v-model="select_helmet_material"
                            prepend-inner-icon="map"
                            hint="Material casco"
                            :items="items_select_helmet_material"
                            item-text="state"
                            item-value="abbr"
                            label="Material casco"
                            persistent-hint
                            return-object
                            single-line
                          ></v-select>
                        </v-col>
                        <v-col cols="4" sm="12" md="4" v-show="title == 'IT'">
                          <v-text-field
                            :readonly="only || concept_only"
                            v-model="number_wineries"
                            prepend-inner-icon
                            placeholder="Numero de bodegas"
                            hint="Numero de bodegas"
                            persistent-hint
                            type="number"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="4" sm="12" md="4" v-show="title != 'CP'">
                          <v-text-field
                            :readonly="only || concept_only"
                            v-model="tall_wineries"
                            prepend-inner-icon
                            placeholder="Alto de la bodega de pescado (m)"
                            hint="Alto de la bodega de pescado (m)"
                            persistent-hint
                            suffix="m"
                            type="number"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="4" sm="12" md="4" v-show="title != 'CP'">
                          <v-text-field
                            :readonly="only || concept_only"
                            v-model="long_wineries"
                            prepend-inner-icon
                            placeholder="Largo de la bodega de pescado (m)"
                            hint="Largo de la bodega de pescado (m)"
                            persistent-hint
                            type="number"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="4" sm="12" md="4" v-show="title != 'CP'">
                          <v-text-field
                            :readonly="only || concept_only"
                            v-model="width_wineries"
                            prepend-inner-icon
                            placeholder="Ancho de la bodega de pescado (m)"
                            hint="Ancho de la bodega de pescado (m)"
                            persistent-hint
                            suffix="m"
                            type="number"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="4" sm="12" md="4" v-show="title != 'CP'">
                          <v-text-field
                            :readonly="only || concept_only"
                            v-model="capacity_wineries"
                            prepend-inner-icon
                            placeholder="Capacidad de la bodega de pescado (m³)"
                            hint="Capacidad de la bodega de pescado (m³)"
                            suffix="m³"
                            persistent-hint
                            type="number"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="4" sm="12" md="4" v-show="title != 'CP'">
                          <v-combobox
                            :readonly="only || concept_only"
                            v-model="select_type_conservation_fish"
                            prepend-inner-icon="map"
                            hint="Tipo de conservación del pescado"
                            :items="items_select_type_conservation_fish"
                            item-text="state"
                            item-value="abbr"
                            label="Tipo de conservación del pescado"
                            persistent-hint
                            return-object
                            single-line
                            chips
                          ></v-combobox>
                        </v-col>
                        <v-col
                          cols="4"
                          sm="12"
                          md="4"
                          v-show="title != 'CP'"
                        ></v-col>
                      </v-row>
                    </v-container>
                    <small>* Indicador de campo requerido</small>
                  </v-card-text>
                </v-card>
                <div class="input-group">
                  <p align="left" style="position: absolute">
                    <v-btn text @click="e1 = 2">Regresar</v-btn>
                  </p>
                  <p align="right">
                    <v-btn color="primary" @click="e1 = 4">Continuar</v-btn>
                  </p>
                </div>
              </v-stepper-content>
              <v-stepper-content v-if="title == 'CP'" step="4">
                <v-card class="mb-12" color="white lighten-1">
                  <v-card-text>
                    <v-container>
                      <v-row>
                        <v-col cols="6" sm="12" md="6">
                          <v-text-field
                            v-if="title == 'CP'"
                            :readonly="only"
                            v-model="manager_operator"
                            prepend-inner-icon
                            clearable
                            label="Nombre del Gerente, Armador, Operador"
                            persistent-hint
                          ></v-text-field>

                          <v-text-field
                            v-if="inputBoatIT"
                            :readonly="only"
                            v-model="maritime_agent"
                            prepend-inner-icon
                            placeholder="Nombre del administrador o Agente Marítimo"
                            clearable
                            hint="Nombre del administrador o Agente Marítimo"
                            persistent-hint
                          ></v-text-field>
                        </v-col>
                        <v-col cols="6" sm="12" md="6" v-show="title == 'CP'">
                          <v-menu
                            :readonly="only"
                            v-model="menuExpeditionDatePatent"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            offset-y
                            min-width="290px"
                          >
                            <template v-slot:activator="{ on }">
                              <v-text-field
                                :readonly="only"
                                v-model="date_expedition_patent"
                                label="Fecha de inicio de vigencia de la patente"
                                prepend-icon="event"
                                v-on="on"
                              ></v-text-field>
                            </template>
                            <v-date-picker
                              :readonly="only"
                              v-model="date_expedition_patent"
                              @input="menuExpeditionDatePatent = false"
                            ></v-date-picker>
                          </v-menu>
                        </v-col>
                        <v-col cols="6" sm="12" md="6" v-show="title == 'CP'">
                          <v-text-field
                            :readonly="only"
                            v-model="validity_patent"
                            prepend-inner-icon
                            hint="Vigencia de la patente (en meses)"
                            clearable
                            label="Vigencia de la patente (meses)"
                            persistent-hint
                            type="number"
                            @input="PatentV()"
                          ></v-text-field>
                        </v-col>

                        <v-col cols="6" sm="12" md="6" v-show="title == 'CP'">
                          <v-menu
                            disabled
                            v-model="menuExpirationDatePatent"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            offset-y
                            min-width="290px"
                          >
                            <template v-slot:activator="{ on }">
                              <v-text-field
                                readonly
                                v-model="date_expiration_patent"
                                label="Fecha de vencimiento de la patente"
                                prepend-icon="event"
                                v-on="on"
                              ></v-text-field>
                            </template>
                            <v-date-picker
                              disabled
                              readonly
                              v-model="date_expiration_patent"
                              @input="menuExpirationDatePatent = false"
                            ></v-date-picker>
                          </v-menu>
                        </v-col>

                        <v-col cols="4" sm="12" md="6" v-show="title != 'CT'">
                          <v-text-field
                            :readonly="only"
                            v-model="crew_number"
                            prepend-inner-icon
                            placeholder="Numero de tripulantes"
                            clearable
                            hint="Numero de tripulantes"
                            persistent-hint
                            type="text"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="4" sm="12" md="6" v-show="title != 'CT'">
                          <v-text-field
                            :readonly="only"
                            v-model="fishermen_number"
                            prepend-inner-icon
                            placeholder="Numero de pescadores"
                            clearable
                            hint="Numero de pescadores"
                            persistent-hint
                            type="number"
                          ></v-text-field>
                        </v-col>

                        <v-col cols="4" sm="12" md="6" v-show="title == 'CP'">
                          <v-combobox
                            :readonly="only || concept_only || select_types_of_fishing.length > 0"
                            v-model="select_types_of_fishing"
                            :items="itemsTypeOfFishery"
                            item-text="nombre"
                            item-value="codigo"
                            label="Tipo de pesquería "
                            hint="Si el campo no está en la lista, escríbalo y de click en ENTER"
                            persistent-hint
                            return-object
                            single-line
                            multiple
                            chips
                          >
                            <template v-slot:selection="data">
                              <v-chip
                                v-bind="data.attrs"
                                :input-value="data.selected"
                                @click="data.select"
                              >
                                {{
                                  data.item.pivot !== undefined
                                    ? data.item.pivot.description
                                    : data.item.nombre !== undefined
                                    ? data.item.nombre
                                    : data.item
                                }}
                              </v-chip>
                            </template>
                          </v-combobox>
                        </v-col>
                      </v-row>
                    </v-container>
                    <small>* Indicador de campo requerido</small>
                  </v-card-text>
                </v-card>
                <div class="input-group">
                  <p align="left" style="position: absolute">
                    <v-btn text @click="e1 = 3">Regresar</v-btn>
                  </p>
                  <p align="right">
                    <v-btn color="primary" @click="e1 = 5">Continuar</v-btn>
                  </p>
                </div>
                <br />
              </v-stepper-content>
              <v-stepper-content :step="title == 'CP' ? '5' : '4'">
                <v-card class="mb-12" color="white lighten-1">
                  <v-card-text>
                    <v-container>
                      <v-row>
                        <v-col cols="6" sm="12" md="6">
                          <v-textarea
                            :readonly="only"
                            v-model="fishing_zone"
                            hint="Área de operaciones"
                            label="Área de operaciones"
                            persistent-hint
                            required
                          ></v-textarea>
                        </v-col>
                        <v-col cols="6">
                          <v-combobox
                            v-model="select_fishing_arts_boat"
                            :items="$store.state.variablesConcepto.items_select_fishing_arts_boat"
                            item-text="nombre"
                            item-value="codigo"
                            label="Artes de pesca"
                            hint="Si el campo no está en la lista, escríbalo y de click en ENTER"
                            persistent-hint
                            return-object
                            single-line
                            multiple
                            chips
                          >
                            <template v-slot:selection="data">
                              <v-chip
                                v-bind="data.attrs"
                                :input-value="data.selected"
                              >
                                {{
                                  data.item.pivot !== undefined
                                    ? data.item.pivot.description
                                    : data.item.nombre !== undefined
                                    ? data.item.nombre
                                    : data.item
                                }}
                              </v-chip>
                            </template>
                          </v-combobox>
                        </v-col>
                        <v-col cols="6" sm="12" md="6" v-show="title == 'IT'">
                          <v-text-field
                            :readonly="only"
                            v-model="fishing_value"
                            prepend-inner-icon
                            placeholder="Valor del arte de pesca ($)"
                            clearable
                            hint="Valor del arte de pesca ($)"
                            persistent-hint
                            type="number"
                          ></v-text-field>
                        </v-col>
                        <v-col
                          cols="12"
                          sm="12"
                          md="12"
                          v-show="
                            title == 'CT' &&
                            select_fishing_arts_boat.find(
                              (x) => x.codigo == 'DOS'
                            ) != undefined
                          "
                        >
                          <v-subheader
                            light
                            class="subtitle-1 font-italic font-weight-bold"
                            >{{
                              select_fishing_arts_boat.find(
                                (x) => x.codigo == "UNO"
                              ) != undefined
                                ? select_fishing_arts_boat.find(
                                    (x) => x.codigo == "UNO"
                                  ).nombre
                                : ""
                            }}</v-subheader
                          >
                          <v-divider light></v-divider>
                        </v-col>
                        <v-col
                          cols="6"
                          sm="12"
                          md="6"
                          v-show="
                            title == 'CT' &&
                            select_fishing_arts_boat.find(
                              (x) => x.codigo == 'UNO'
                            ) != undefined
                          "
                        >
                          <v-combobox
                            :readonly="only"
                            v-model="select_type_line"
                            :items="items_select_type_line"
                            hide-selected
                            item-text="state"
                            item-value="abbr"
                            hint="Si el campo no está en la lista, escríbalo y de click en ENTER"
                            label="Denominación del arte de pesca tipo Sedal ó de anzuelo"
                            persistent-hint
                            small-chips
                          ></v-combobox>
                        </v-col>
                        <v-col
                          cols="6"
                          sm="12"
                          md="6"
                          v-show="
                            title == 'CT' &&
                            select_fishing_arts_boat.find(
                              (x) => x.codigo == 'UNO'
                            ) != undefined
                          "
                        >
                          <v-combobox
                            :readonly="only"
                            v-model="select_type_hook"
                            :items="items_select_type_hook"
                            hide-selected
                            item-text="state"
                            item-value="abbr"
                            hint="Si el campo no está en la lista, escríbalo y de click en ENTER"
                            label="Tipo de anzuelo"
                            persistent-hint
                            small-chips
                          ></v-combobox>
                        </v-col>
                        <v-col
                          cols="6"
                          sm="12"
                          md="6"
                          v-show="
                            title == 'CT' &&
                            select_fishing_arts_boat.find(
                              (x) => x.codigo == 'UNO'
                            ) != undefined
                          "
                        >
                          <v-text-field
                            :readonly="only"
                            v-model="hook_size_type"
                            label="Tamaño de anzuelo"
                            hint="Tamaño de anzuelo"
                          ></v-text-field>
                        </v-col>
                        <v-col
                          cols="6"
                          sm="12"
                          md="6"
                          v-show="
                            title == 'CT' &&
                            select_fishing_arts_boat.find(
                              (x) => x.codigo == 'UNO'
                            ) != undefined
                          "
                        >
                          <v-text-field
                            :readonly="only"
                            v-model="hook_quantity"
                            label="Cantidad de anzuelos"
                            hint="Cantidad de anzuelos"
                          ></v-text-field>
                        </v-col>
                        <v-col
                          cols="6"
                          sm="12"
                          md="6"
                          v-show="
                            title == 'CT' &&
                            select_fishing_arts_boat.find(
                              (x) => x.codigo == 'UNO'
                            ) != undefined
                          "
                        >
                          <v-text-field
                            :readonly="only"
                            v-model="mother_line_length"
                            label="Longitud de la línea madre (m)"
                            hint="Longitud de la línea madre medida en brazas"
                            persistent-hint
                          ></v-text-field>
                        </v-col>
                        <v-col
                          cols="6"
                          sm="12"
                          md="6"
                          v-show="
                            title == 'CT' &&
                            select_fishing_arts_boat.find(
                              (x) => x.codigo == 'UNO'
                            ) != undefined
                          "
                        >
                          <v-combobox
                            :readonly="only"
                            v-model="select_mother_line_material"
                            :items="items_select_mother_line_material"
                            item-text="state"
                            item-value="abbr"
                            hint="Si el campo no está en la lista, escríbalo y de click en ENTER"
                            label="Material de la línea madre"
                            persistent-hint
                            small-chips
                          ></v-combobox>
                        </v-col>
                        <v-col
                          cols="6"
                          sm="12"
                          md="6"
                          v-show="
                            title == 'CT' &&
                            select_fishing_arts_boat.find(
                              (x) => x.codigo == 'UNO'
                            ) != undefined
                          "
                        >
                          <v-combobox
                            :readonly="only"
                            v-model="select_downspout_material"
                            :items="items_select_downspout_material"
                            hide-selected
                            item-text="state"
                            item-value="abbr"
                            hint="Si el campo no está en la lista, escríbalo y de click en ENTER"
                            label="Material de las bajantes"
                            persistent-hint
                            small-chips
                          ></v-combobox>
                        </v-col>
                        <v-col
                          cols="6"
                          sm="12"
                          md="6"
                          v-show="
                            title == 'CT' &&
                            select_fishing_arts_boat.find(
                              (x) => x.codigo == 'UNO'
                            ) != undefined
                          "
                        >
                          <v-text-field
                            :readonly="only"
                            v-model="total_number_lines"
                            label="Cantidad total de líneas"
                            hint="Cantidad total de líneas"
                          ></v-text-field>
                        </v-col>
                        <v-col
                          cols="12"
                          sm="12"
                          md="12"
                          v-show="
                            title == 'CT' &&
                            select_fishing_arts_boat.find(
                              (x) => x.codigo == 'DOS'
                            ) != undefined
                          "
                        >
                          <v-subheader
                            light
                            class="subtitle-1 font-italic font-weight-bold"
                            >{{
                              select_fishing_arts_boat.find(
                                (x) => x.codigo == "DOS"
                              ) != undefined
                                ? select_fishing_arts_boat.find(
                                    (x) => x.codigo == "DOS"
                                  ).nombre
                                : ""
                            }}</v-subheader
                          >
                          <v-divider light></v-divider>
                        </v-col>
                        <v-col
                          cols="6"
                          sm="12"
                          md="6"
                          v-show="
                            title == 'CT' &&
                            select_fishing_arts_boat.find(
                              (x) => x.codigo == 'DOS'
                            ) != undefined
                          "
                        >
                          <v-combobox
                            :readonly="only"
                            v-model="select_denomination_art_fishing"
                            :items="items_select_denomination_art_fishing"
                            hide-selected
                            item-text="state"
                            item-value="abbr"
                            hint="Si el campo no está en la lista, escríbalo y de click en ENTER"
                            label="Denominación del arte de pesca tipo trampas o nasas"
                            persistent-hint
                            small-chips
                          ></v-combobox>
                        </v-col>
                        <v-col
                          cols="6"
                          sm="12"
                          md="6"
                          v-show="
                            title == 'CT' &&
                            select_fishing_arts_boat.find(
                              (x) => x.codigo == 'DOS'
                            ) != undefined
                          "
                        >
                          <v-text-field
                            :readonly="only"
                            v-model="cheating_amount"
                            label="Cantidad de trampas o nasas"
                            hint="Cantidad de trampas o nasas"
                          ></v-text-field>
                        </v-col>
                        <v-col
                          cols="6"
                          sm="12"
                          md="6"
                          v-show="
                            title == 'CT' &&
                            select_fishing_arts_boat.find(
                              (x) => x.codigo == 'DOS'
                            ) != undefined
                          "
                        >
                          <v-combobox
                            :readonly="only"
                            v-model="select_main_trap_material"
                            :items="items_select_main_trap_material"
                            hide-selected
                            item-text="state"
                            item-value="abbr"
                            hint="Si el campo no está en la lista, escríbalo y de click en ENTER"
                            label="Material principal de la trampa o nasa"
                            persistent-hint
                            small-chips
                          ></v-combobox>
                        </v-col>
                        <v-col
                          cols="12"
                          sm="12"
                          md="12"
                          v-show="
                            title == 'CT' &&
                            select_fishing_arts_boat.find(
                              (x) => x.codigo == 'TRES'
                            ) != undefined
                          "
                        >
                          <v-subheader
                            light
                            class="subtitle-1 font-italic font-weight-bold"
                            >{{
                              select_fishing_arts_boat.find(
                                (x) => x.codigo == "TRES"
                              ) != undefined
                                ? select_fishing_arts_boat.find(
                                    (x) => x.codigo == "TRES"
                                  ).nombre
                                : ""
                            }}</v-subheader
                          >
                          <v-divider light></v-divider>
                        </v-col>
                        <v-col
                          cols="6"
                          sm="12"
                          md="6"
                          v-show="
                            title == 'CT' &&
                            select_fishing_arts_boat.find(
                              (x) => x.codigo == 'TRES'
                            ) != undefined
                          "
                        >
                          <v-combobox
                            :readonly="only"
                            v-model="select_type_artifact"
                            :items="items_select_type_artifact"
                            hide-selected
                            item-text="state"
                            item-value="abbr"
                            hint="Si el campo no está en la lista, escríbalo y de click en ENTER"
                            label="Tipo de artefactos de herir o aferrar"
                            persistent-hint
                            small-chips
                          ></v-combobox>
                        </v-col>
                        <v-col
                          cols="6"
                          sm="12"
                          md="6"
                          v-show="
                            title == 'CT' &&
                            select_fishing_arts_boat.find(
                              (x) => x.codigo == 'TRES'
                            ) != undefined
                          "
                        >
                          <v-text-field
                            :readonly="only"
                            v-model="number_artifacts"
                            label="Cantidad de artefactos"
                            hint="Cantidad de artefactos"
                          ></v-text-field>
                        </v-col>
                        <v-col
                          cols="6"
                          sm="12"
                          md="6"
                          v-show="
                            title == 'CT' &&
                            select_fishing_arts_boat.find(
                              (x) => x.codigo == 'TRES'
                            ) != undefined
                          "
                        >
                          <v-combobox
                            :readonly="only"
                            v-model="select_artifact_material"
                            :items="items_select_artifact_material"
                            hide-selected
                            item-text="state"
                            item-value="abbr"
                            hint="Si el campo no está en la lista, escríbalo y de click en ENTER"
                            label="Material del artefacto"
                            persistent-hint
                            small-chips
                          ></v-combobox>
                        </v-col>
                        <v-col cols="12" sm="12" md="12" v-show="title == 'CT'">
                          <v-divider light></v-divider>
                        </v-col>
                        <v-col cols="6" sm="12" md="6" v-show="title == 'CT'">
                          <v-combobox
                            :readonly="only"
                            v-model="select_type_fad"
                            :items="items_select_type_fad"
                            hide-selected
                            item-text="state"
                            item-value="abbr"
                            hint="Si el campo no está en la lista, escríbalo y de click en ENTER"
                            label="Tipo de FAD utilizados"
                            persistent-hint
                            small-chips
                          ></v-combobox>
                        </v-col>
                        <v-col cols="6" sm="12" md="6" v-show="title == 'CT'">
                          <v-text-field
                            :readonly="only"
                            v-model="quantity_fad"
                            label="Cantidad de FAD utilizados"
                            hint="Cantidad de FAD utilizados"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="6" sm="12" md="6" v-show="title == 'CT'">
                          <v-text-field
                            :readonly="only"
                            v-model="components_fad"
                            label="Componentes del FAD"
                            hint="Componentes del FAD"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>
                </v-card>

                <div class="input-group">
                  <p align="left" style="position: absolute">
                    <v-btn text @click="e1 = 4" v-if="title == 'CP'"
                      >Regresar</v-btn
                    >
                    <v-btn text @click="e1 = 3" v-if="title == 'CT'"
                      >Regresar</v-btn
                    >
                  </p>
                  <p align="right">
                    <v-btn color="primary" @click="e1 = 5" v-if="title == 'IT'"
                      >Continuar</v-btn
                    >
                  </p>
                </div>
                <br />
                <br />
              </v-stepper-content>

              <v-stepper-content step="6" v-if="title == 'IT'">
                <v-card class="mb-12" color="white lighten-1">
                  <v-card-text>
                    <v-container>
                      <v-row>
                        <v-col cols="3" sm="12" md="3">
                          <p>Cartas de navegación</p>
                          <v-radio-group
                            :readonly="only"
                            v-model="radiosChart"
                            row
                          >
                            <v-radio label="Si" value="SI"></v-radio>
                            <v-radio label="No" value="NO"></v-radio>
                          </v-radio-group>
                        </v-col>
                        <v-col cols="3" sm="12" md="3">
                          <p>Compas Magnetico</p>
                          <v-radio-group
                            :readonly="only"
                            v-model="radiosMagneticCompass"
                            row
                          >
                            <v-radio label="Si" value="SI"></v-radio>
                            <v-radio label="No" value="NO"></v-radio>
                          </v-radio-group>
                        </v-col>
                        <v-col cols="3" sm="12" md="3">
                          <p>GPS</p>
                          <v-radio-group
                            :readonly="only"
                            v-model="radiosGps"
                            row
                          >
                            <v-radio label="Si" value="SI"></v-radio>
                            <v-radio label="No" value="NO"></v-radio>
                          </v-radio-group>
                        </v-col>
                        <v-col cols="3" sm="12" md="3">
                          <p>Loran</p>
                          <v-radio-group
                            :readonly="only"
                            v-model="radiosLoran"
                            row
                          >
                            <v-radio label="Si" value="SI"></v-radio>
                            <v-radio label="No" value="NO"></v-radio>
                          </v-radio-group>
                        </v-col>
                        <v-col cols="3" sm="12" md="3">
                          <p>Radar</p>
                          <v-radio-group
                            :readonly="only"
                            v-model="radiosRadar"
                            row
                          >
                            <v-radio label="Si" value="SI"></v-radio>
                            <v-radio label="No" value="NO"></v-radio>
                          </v-radio-group>
                        </v-col>
                        <v-col cols="3" sm="12" md="3">
                          <p>Ecosonda</p>
                          <v-radio-group
                            :readonly="only"
                            v-model="radiosEcosonda"
                            row
                          >
                            <v-radio label="Si" value="SI"></v-radio>
                            <v-radio label="No" value="NO"></v-radio>
                          </v-radio-group>
                        </v-col>
                        <v-col cols="3" sm="12" md="3">
                          <p>Radios de comunicación</p>
                          <v-radio-group
                            :readonly="only"
                            v-model="radiosRadios"
                            row
                          >
                            <v-radio label="Si" value="SI"></v-radio>
                            <v-radio label="No" value="NO"></v-radio>
                          </v-radio-group>
                        </v-col>
                      </v-row>
                    </v-container>
                    <small>* Indicador de campo requerido</small>
                  </v-card-text>
                </v-card>
                <v-btn text @click="e1 = 5">Regresar</v-btn>
              </v-stepper-content>
            </v-stepper-items>
          </v-stepper>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="indigo darken-1 white--text"
            v-if="only == false || type_request == 'd'"
            @click="saveBoatParcial"
            style="background-color: #258b91 !important"
            >Guardar Parcial</v-btn
          >
          <v-btn
            color="indigo darken-4 white--text"
            v-if="only == false || type_request == 'd'"
            @click="saveBoat"
            >Guardar Todo</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { VMoney } from "v-money";
import {
  saveBoatUrl,
  saveBoatParcialUrl,
  getHeader,
  getFisherman,
} from "./../config.js";
import { requestTypes } from "../enums/requestTypes.js";

export default {
  props: [
    "title",
    "type",
    "dialog",
    "process",
    "type_request",
    "consecutive",
    "select_types_of_fishing_2",
  ],
  data: () => ({
    company_id: "",
    boat_name: "",
    boatId: "",
    e1: 1,
    value_vessel: 0,
    dialogBoat: false,
    external_marking: "",
    eslora_length: 0,
    extreme_breadth: 0,
    showPermissionIn: false,
    place_issue_registration: "",
    registration_tonnage: 0,
    registration_tonnage_gross: 0,
    select_propulsion_type: { state: "", abbr: "" },
    items_select_propulsion_type: [
      { state: "1. Vela", abbr: "UNO" },
      { state: "2. Remo", abbr: "DOS" },
      { state: "3. Vara", abbr: "TRES" },
      { state: "4. Motor Fuera de Borda", abbr: "CUAATRO" },
      { state: "5. Motor Interno", abbr: "CINCO" },
      { state: "6. Otro", abbr: "SEIS" },
      { state: "7. Desconocido", abbr: "SIETE" },
    ],
    moneyMask: {
      decimal: ".",
      thousands: ".",
      precision: 0,
      masked: false,
    },
    rulesPhoto: [
      (value) =>
        !value ||
        value.size < 2000000 ||
        "Avatar size should be less than 2 MB!",
    ],
    main_motor_power: 0,
    number_wineries: 0,
    tall_wineries: 0,
    long_wineries: 0,
    width_wineries: 0,

    sell_products_to: "",
    vessel_name: "",
    legal_representative: "",
    owner_name: "",
    identification_number: "",
    select_type_document: { state: "CC - Cédula de ciudadanía", abbr: "CC" },
    items_select_type_document: [
      { state: "CC - Cédula de ciudadanía", abbr: "CC" },
      { state: "CE - Cédula de Extranjeria", abbr: "CE" },
    ],
    alertNotFound: false,
    nfound: true,
    radiosChart: "NA",
    radiosMagneticCompass: "NA",
    radiosGps: "NA",
    radiosLoran: "NA",
    radiosRadar: "NA",
    radiosEcosonda: "NA",
    radiosRadios: "NA",
    radiosCurrentFishingPermit: "SI",
    fishermen_number: 0,
    crew_number: 0,
    fishing_value: 0,
    fishing_zone: "",
    built_year: "",
    calado: "",
    built_country: { state: "", abbr: "" },
    hook_size_type: "",
    hook_quantity: "",
    mother_line_length: "",
    total_number_lines: 0,
    cheating_amount: "",
    number_artifacts: 0,
    components_fad: "",
    quantity_fad: 0,
    boat_number: "",
    only: false,
    concept_only: false,
    boat_unique_id: { state: "", abbr: "" },
    items_boat_unique_id: [
      { state: "1. OMI", abbr: "UNO" },
      { state: "2. NIC", abbr: "DOS" },
      { state: "3. NINGUNO", abbr: "TRES" },
    ],

    select_fishing_port: { state: "", abbr: "" },
    items_select_fishing_port: [
      { state: "1. SAI", abbr: "UNO" },
      { state: "2. PROVIDENCIA", abbr: "DOS" },
    ],

    select_type_fad: null,
    items_select_type_fad: [
      { state: "FAD Natural", abbr: "1" },
      { state: "FAD Artificial", abbr: "2" },
      { state: "Payao", abbr: "3" },
      { state: "Arrecife artificial", abbr: "4" },
      { state: "Casita cubana", abbr: "5" },
      /*{ state: "Otro, Cual?", abbr: "6" }*/
    ],
    select_artifact_material: null,
    items_select_artifact_material: [
      { state: "Acero", abbr: "1" },
      { state: "Hierro", abbr: "2" },
      { state: "Madera", abbr: "3" },
      /*{ state: "Otro, Cual?", abbr: "4" }*/
    ],
    select_type_artifact: null,
    items_select_type_artifact: [
      { state: "Arpones", abbr: "1" },
      { state: "Lanzas", abbr: "2" },
      { state: "Flechas", abbr: "3" },
      { state: "Pinchos", abbr: "4" },
      { state: "Horcas", abbr: "5" },
      { state: "Tenazas", abbr: "6" },
    ],
    select_main_trap_material: null,
    items_select_main_trap_material: [
      { state: "Madera y malla metálica", abbr: "1" },
      { state: "Madera y malla plastica", abbr: "2" },
      { state: "Madera y malla en polifilamento", abbr: "3" },
      { state: "Varilla y malla metálica", abbr: "4" },
      { state: "Varilla y malla plastica", abbr: "5" },
      { state: "Varilla y malla en polifilamento", abbr: "6" },
      { state: "Plástico", abbr: "7" },
      { state: "Madera y acero", abbr: "8" },
    ],
    select_denomination_art_fishing: null,
    items_select_denomination_art_fishing: [
      { state: "Nasas para peces", abbr: "1" },
      { state: "Nasas para crustáceos", abbr: "2" },
      { state: "Nasas para moluscos", abbr: "3" },
    ],
    select_downspout_material: null,
    items_select_downspout_material: [
      { state: "Monofilamento", abbr: "1" },
      { state: "Polifilamento", abbr: "2" },
      { state: "Acero", abbr: "3" },
      { state: "Polifilamento", abbr: "5" },
    ],
    select_type_hook: null,
    items_select_type_hook: [
      { state: "Jota", abbr: "1" },
      { state: "Circular", abbr: "2" },
    ],
    select_mother_line_material: null,
    items_select_mother_line_material: [
      { state: "Monofilamento", abbr: "1" },
      { state: "Multifilamento", abbr: "2" },
    ],
    select_type_line: null,
    items_select_type_line: [
      { state: "1. Long line", abbr: "1" },
      { state: "2. Espinel", abbr: "2" },
      { state: "3. Palangre de fondo - reel", abbr: "3" },
      { state: "4. Palangre de deriva", abbr: "4" },
      { state: "5. Curricanes", abbr: "5" },
      { state: "6. Ballestilla", abbr: "6" },
      { state: "7. Cordel - Línea de mano", abbr: "7" },
    ],
    select_type_conservation_fish: { state: "", abbr: "" },
    items_select_type_conservation_fish: [
      { state: "1. Fresh – Fresco", abbr: "1" },
      { state: "2. Live – Vivo", abbr: "2" },
      { state: "3. Frozen – Congelado", abbr: "3" },
      { state: "98. Other – Otro", abbr: "98" },
      { state: "99. Unknown - Desconocido", abbr: "99" },
    ],
    select_helmet_material: { state: "", abbr: "" },
    items_select_helmet_material: [
      { state: "1. Wood - Madera", abbr: "1" },
      { state: "2. Marine Plywood – Madera forrada", abbr: "2" },
      { state: "3. Aluminium – Aluminio", abbr: "3" },
      { state: "4. Iron/Steel - Hierro / Acero", abbr: "4" },
      { state: "5. Fibreglass – Fibra de vidrio", abbr: "5" },
      { state: "6. Rubber – Caucho", abbr: "6" },
      { state: "7. Cement – Cemento", abbr: "7" },
      { state: "99. Unknown - Desconocido", abbr: "99" },
    ],
    select_power_unit: { state: "", abbr: "" },
    items_select_power_unit: [
      { state: "KW. Kilowatt", abbr: "KW" },
      { state: "HP. Horse Power", abbr: "HP" },
    ],

    select_authorized_landing_port: { nombre: "", codigo: "" },

    items_select_authorized_landing_port: [],
    select_vessel_type: null,
    items_select_vessel_type: [
      { state: "1. TRAWLERS - ARRASTRERO", abbr: "1" },
      { state: "2. SEINERS - CERQUERO", abbr: "2" },
      { state: "3. DREDGERS - RASTRAS", abbr: "3" },
      { state: "4. LIFT NETTERS – REDES IZADAS", abbr: "4" },
      { state: "5. GILLNETTERS - BARCO AGALLERO", abbr: "5" },
      { state: "6. TRAP SETTERS – BARCOS CON TRAMPAS", abbr: "6" },
      { state: "7. LINERS – BARCOS DE LINEA", abbr: "7" },
      {
        state: "8. VESSELS USING PUMPS FOR FISHING - BARCO CON BOMBAS",
        abbr: "8",
      },
      { state: "9. MULTIPURPOSE VESSELS - POLIVALENTES", abbr: "9" },
      {
        state: "10. RECREATIONAL FISHING VESSELS - RECREACIONES",
        abbr: "10",
      },
      { state: "49. FISHING VESSELS NOT SPECIFIED", abbr: "11" },
    ],
    select_registration_port: null,
    items_select_registration_port: [],
    select_current_state_pavilion: { state: "", abbr: "" },
    items_select_current_state_pavilion: [
      { state: "AFG. Afghanistan", abbr: "AFG" },
      { state: "ALB. Albania", abbr: "ALB" },
      { state: "DZA. Algeria", abbr: "DZA" },
      { state: "ASM. American Samoa", abbr: "ASM" },
      { state: "AND. Andorra", abbr: "AND" },
      { state: "AGO. Angola", abbr: "AGO" },
      { state: "AIA. Anguilla", abbr: "AIA" },
      { state: "ATA. Antarctica", abbr: "ATA" },
      { state: "ATG. Antigua and Barbuda", abbr: "ATG" },
      { state: "ARG. Argentina", abbr: "ARG" },
      { state: "ARM. Armenia", abbr: "ARM" },
      { state: "ABW. Aruba", abbr: "ABW" },
      { state: "AUS. Australia", abbr: "AUS" },
      { state: "AUT. Austria", abbr: "AUT" },
      { state: "AZE. Azerbaijan", abbr: "AZE" },
      { state: "BHS. Bahamas", abbr: "BHS" },
      { state: "BHR. Bahrain", abbr: "BHR" },
      { state: "BGD. Bangladesh", abbr: "BGD" },
      { state: "BRB. Barbados", abbr: "BRB" },
      { state: "BLR. Belarus", abbr: "BLR" },
      { state: "BEL. Belgium", abbr: "BEL" },
      { state: "BLZ. Belize", abbr: "BLZ" },
      { state: "BEN. Benin", abbr: "BEN" },
      { state: "BMU. Bermuda", abbr: "BMU" },
      { state: "BTN. Bhutan", abbr: "BTN" },
      { state: "BO. Bolivia", abbr: "BO" },
      { state: "BOL. Bolivia", abbr: "BOL" },
      { state: "BIH. Bosnia and Herzegovina", abbr: "BIH" },
      { state: "BWA. Botswana", abbr: "BWA" },
      { state: "BVT. Bouvet Island", abbr: "BVT" },
      { state: "BRA. Brazil", abbr: "BRA" },
      { state: "IOT. British Indian Ocean Territory", abbr: "IOT" },
      { state: "BRN. Brunei Darussalam", abbr: "BRN" },
      { state: "BRN. Brunei", abbr: "BRN" },
      { state: "BGR. Bulgaria", abbr: "BGR" },
      { state: "BFA. Burkina Faso", abbr: "BFA" },
      { state: "BDI. Burundi", abbr: "BDI" },
      { state: "KHM. Cambodia", abbr: "KHM" },
      { state: "CMR. Cameroon", abbr: "CMR" },
      { state: "CAN. Canada", abbr: "CAN" },
      { state: "CPV. Cape Verde", abbr: "CPV" },
      { state: "CYM. Cayman Islands", abbr: "CYM" },
      { state: "CAF. Central African Republic", abbr: "CAF" },
      { state: "TCD. Chad", abbr: "TCD" },
      { state: "CHL. Chile", abbr: "CHL" },
      { state: "CHN. China", abbr: "CHN" },
      { state: "CXR. Christmas Island", abbr: "CXR" },
      { state: "CCK. Cocos (Keeling) Islands", abbr: "CCK" },
      { state: "COL. Colombia", abbr: "COL" },
      { state: "COM. Comoros", abbr: "COM" },
      { state: "COG. Congo", abbr: "COG" },
      { state: "CD. Congo", abbr: "CD" },
      { state: "COK. Cook Islands", abbr: "COK" },
      { state: "CRI. Costa Rica", abbr: "CRI" },
      { state: "CIV. CÃ´te d'Ivoire", abbr: "CIV" },
      { state: "CIV. Ivory Coast", abbr: "CIV" },
      { state: "HRV. Croatia", abbr: "HRV" },
      { state: "CUB. Cuba", abbr: "CUB" },
      { state: "CYP. Cyprus", abbr: "CYP" },
      { state: "CZE. Czech Republic", abbr: "CZE" },
      { state: "DNK. Denmark", abbr: "DNK" },
      { state: "DJI. Djibouti", abbr: "DJI" },
      { state: "DMA. Dominica", abbr: "DMA" },
      { state: "DOM. Dominican Republic", abbr: "DOM" },
      { state: "ECU. Ecuador", abbr: "ECU" },
      { state: "EGY. Egypt", abbr: "EGY" },
      { state: "SLV. El Salvador", abbr: "SLV" },
      { state: "GNQ. Equatorial Guinea", abbr: "GNQ" },
      { state: "ERI. Eritrea", abbr: "ERI" },
      { state: "EST. Estonia", abbr: "EST" },
      { state: "ETH. Ethiopia", abbr: "ETH" },
      { state: "FLK. Falkland Islands (Malvinas)", abbr: "FLK" },
      { state: "FRO. Faroe Islands", abbr: "FRO" },
      { state: "FJI. Fiji", abbr: "FJI" },
      { state: "FIN. Finland", abbr: "FIN" },
      { state: "FRA. France", abbr: "FRA" },
      { state: "GUF. French Guiana", abbr: "GUF" },
      { state: "PYF. French Polynesia", abbr: "PYF" },
      { state: "ATF. French Southern Territories", abbr: "ATF" },
      { state: "GAB. Gabon", abbr: "GAB" },
      { state: "GMB. Gambia", abbr: "GMB" },
      { state: "GEO. Georgia", abbr: "GEO" },
      { state: "DEU. Germany", abbr: "DEU" },
      { state: "GHA. Ghana", abbr: "GHA" },
      { state: "GIB. Gibraltar", abbr: "GIB" },
      { state: "GRC. Greece", abbr: "GRC" },
      { state: "GRL. Greenland", abbr: "GRL" },
      { state: "GRD. Grenada", abbr: "GRD" },
      { state: "GLP. Guadeloupe", abbr: "GLP" },
      { state: "GUM. Guam", abbr: "GUM" },
      { state: "GTM. Guatemala", abbr: "GTM" },
      { state: "GGY. Guernsey", abbr: "GGY" },
      { state: "GIN. Guinea", abbr: "GIN" },
      { state: "GNB. Guinea-Bissau", abbr: "GNB" },
      { state: "GUY. Guyana", abbr: "GUY" },
      { state: "HTI. Haiti", abbr: "HTI" },
      { state: "HMD. Heard Island and McDonald Islands", abbr: "HMD" },
      { state: "VAT. Holy See (Vatican City State)", abbr: "VAT" },
      { state: "HND. Honduras", abbr: "HND" },
      { state: "HKG. Hong Kong", abbr: "HKG" },
      { state: "HUN. Hungary", abbr: "HUN" },
      { state: "ISL. Iceland", abbr: "ISL" },
      { state: "IND. India", abbr: "IND" },
      { state: "IDN. Indonesia", abbr: "IDN" },
      { state: "IR. Iran", abbr: "IR" },
      { state: "IRQ. Iraq", abbr: "IRQ" },
      { state: "IRL. Ireland", abbr: "IRL" },
      { state: "IMN. Isle of Man", abbr: "IMN" },
      { state: "ISR. Israel", abbr: "ISR" },
      { state: "ITA. Italy", abbr: "ITA" },
      { state: "JAM. Jamaica", abbr: "JAM" },
      { state: "JPN. Japan", abbr: "JPN" },
      { state: "JEY. Jersey", abbr: "JEY" },
      { state: "JOR. Jordan", abbr: "JOR" },
      { state: "KAZ. Kazakhstan", abbr: "KAZ" },
      { state: "KEN. Kenya", abbr: "KEN" },
      { state: "KIR. Kiribati", abbr: "KIR" },
      { state: "KP. Korea", abbr: "KP" },
      { state: "KR. Korea", abbr: "KR" },
      { state: "KOR. South Korea", abbr: "KOR" },
      { state: "KWT. Kuwait", abbr: "KWT" },
      { state: "KGZ. Kyrgyzstan", abbr: "KGZ" },
      { state: "LAO. Lao People's Democratic Republic", abbr: "LAO" },
      { state: "LVA. Latvia", abbr: "LVA" },
      { state: "LBN. Lebanon", abbr: "LBN" },
      { state: "LSO. Lesotho", abbr: "LSO" },
      { state: "LBR. Liberia", abbr: "LBR" },
      { state: "LBY. Libyan Arab Jamahiriya", abbr: "LBY" },
      { state: "LBY. Libya", abbr: "LBY" },
      { state: "LIE. Liechtenstein", abbr: "LIE" },
      { state: "LTU. Lithuania", abbr: "LTU" },
      { state: "LUX. Luxembourg", abbr: "LUX" },
      { state: "MAC. Macao", abbr: "MAC" },
      { state: "MK. Macedonia", abbr: "MK" },
      { state: "MDG. Madagascar", abbr: "MDG" },
      { state: "MWI. Malawi", abbr: "MWI" },
      { state: "MYS. Malaysia", abbr: "MYS" },
      { state: "MDV. Maldives", abbr: "MDV" },
      { state: "MLI. Mali", abbr: "MLI" },
      { state: "MLT. Malta", abbr: "MLT" },
      { state: "MHL. Marshall Islands", abbr: "MHL" },
      { state: "MTQ. Martinique", abbr: "MTQ" },
      { state: "MRT. Mauritania", abbr: "MRT" },
      { state: "MUS. Mauritius", abbr: "MUS" },
      { state: "MYT. Mayotte", abbr: "MYT" },
      { state: "MEX. Mexico", abbr: "MEX" },
      { state: "FM. Micronesia", abbr: "FM" },
      { state: "MD. Moldova", abbr: "MD" },
      { state: "MCO. Monaco", abbr: "MCO" },
      { state: "MNG. Mongolia", abbr: "MNG" },
      { state: "MNE. Montenegro", abbr: "MNE" },
      { state: "MSR. Montserrat", abbr: "MSR" },
      { state: "MAR. Morocco", abbr: "MAR" },
      { state: "MOZ. Mozambique", abbr: "MOZ" },
      { state: "MMR. Myanmar", abbr: "MMR" },
      { state: "MMR. Burma", abbr: "MMR" },
      { state: "NAM. Namibia", abbr: "NAM" },
      { state: "NRU. Nauru", abbr: "NRU" },
      { state: "NPL. Nepal", abbr: "NPL" },
      { state: "NLD. Netherlands", abbr: "NLD" },
      { state: "ANT. Netherlands Antilles", abbr: "ANT" },
      { state: "NCL. New Caledonia", abbr: "NCL" },
      { state: "NZL. New Zealand", abbr: "NZL" },
      { state: "NIC. Nicaragua", abbr: "NIC" },
      { state: "NER. Niger", abbr: "NER" },
      { state: "NGA. Nigeria", abbr: "NGA" },
      { state: "NIU. Niue", abbr: "NIU" },
      { state: "NFK. Norfolk Island", abbr: "NFK" },
      { state: "MNP. Northern Mariana Islands", abbr: "MNP" },
      { state: "NOR. Norway", abbr: "NOR" },
      { state: "OMN. Oman", abbr: "OMN" },
      { state: "PAK. Pakistan", abbr: "PAK" },
      { state: "PLW. Palau", abbr: "PLW" },
      { state: "PS. Palestinian Territory", abbr: "PS" },
      { state: "PAN. Panama", abbr: "PAN" },
      { state: "PNG. Papua New Guinea", abbr: "PNG" },
      { state: "PRY. Paraguay", abbr: "PRY" },
      { state: "PER. Peru", abbr: "PER" },
      { state: "PHL. Philippines", abbr: "PHL" },
      { state: "PCN. Pitcairn", abbr: "PCN" },
      { state: "POL. Poland", abbr: "POL" },
      { state: "PRT. Portugal", abbr: "PRT" },
      { state: "PRI. Puerto Rico", abbr: "PRI" },
      { state: "QAT. Qatar", abbr: "QAT" },
      { state: "REU. RÃ©union", abbr: "REU" },
      { state: "ROU. Romania", abbr: "ROU" },
      { state: "RUS. Russian Federation", abbr: "RUS" },
      { state: "RUS. Russia", abbr: "RUS" },
      { state: "RWA. Rwanda", abbr: "RWA" },
      { state: "SH. Saint Helena", abbr: "SH" },
      { state: "KNA. Saint Kitts and Nevis", abbr: "KNA" },
      { state: "LCA. Saint Lucia", abbr: "LCA" },
      { state: "SPM. Saint Pierre and Miquelon", abbr: "SPM" },
      { state: "VCT. Saint Vincent and the Grenadines", abbr: "VCT" },
      { state: "VCT. Saint Vincent & the Grenadines", abbr: "VCT" },
      { state: "VCT. St. Vincent and the Grenadines", abbr: "VCT" },
      { state: "WSM. Samoa", abbr: "WSM" },
      { state: "SMR. San Marino", abbr: "SMR" },
      { state: "STP. Sao Tome and Principe", abbr: "STP" },
      { state: "SAU. Saudi Arabia", abbr: "SAU" },
      { state: "SEN. Senegal", abbr: "SEN" },
      { state: "SRB. Serbia", abbr: "SRB" },
      { state: "SYC. Seychelles", abbr: "SYC" },
      { state: "SLE. Sierra Leone", abbr: "SLE" },
      { state: "SGP. Singapore", abbr: "SGP" },
      { state: "SVK. Slovakia", abbr: "SVK" },
      { state: "SVN. Slovenia", abbr: "SVN" },
      { state: "SLB. Solomon Islands", abbr: "SLB" },
      { state: "SOM. Somalia", abbr: "SOM" },
      { state: "ZAF. South Africa", abbr: "ZAF" },
      {
        state: "SGS. South Georgia and the South Sandwich Islands",
        abbr: "SGS",
      },
      { state: "ESP. Spain", abbr: "ESP" },
      { state: "LKA. Sri Lanka", abbr: "LKA" },
      { state: "SDN. Sudan", abbr: "SDN" },
      { state: "SUR. Suriname", abbr: "SUR" },
      { state: "SJM. Svalbard and Jan Mayen", abbr: "SJM" },
      { state: "SWZ. Swaziland", abbr: "SWZ" },
      { state: "SWE. Sweden", abbr: "SWE" },
      { state: "CHE. Switzerland", abbr: "CHE" },
      { state: "SYR. Syrian Arab Republic", abbr: "SYR" },
      { state: "TW. Taiwan", abbr: "TW" },
      { state: "TWN. Taiwan", abbr: "TWN" },
      { state: "TJK. Tajikistan", abbr: "TJK" },
      { state: "TZ. Tanzania", abbr: "TZ" },
      { state: "THA. Thailand", abbr: "THA" },
      { state: "TLS. Timor-Leste", abbr: "TLS" },
      { state: "TGO. Togo", abbr: "TGO" },
      { state: "TKL. Tokelau", abbr: "TKL" },
      { state: "TON. Tonga", abbr: "TON" },
      { state: "TTO. Trinidad and Tobago", abbr: "TTO" },
      { state: "TTO. Trinidad & Tobago", abbr: "TTO" },
      { state: "TUN. Tunisia", abbr: "TUN" },
      { state: "TUR. Turkey", abbr: "TUR" },
      { state: "TKM. Turkmenistan", abbr: "TKM" },
      { state: "TCA. Turks and Caicos Islands", abbr: "TCA" },
      { state: "TUV. Tuvalu", abbr: "TUV" },
      { state: "UGA. Uganda", abbr: "UGA" },
      { state: "UKR. Ukraine", abbr: "UKR" },
      { state: "ARE. United Arab Emirates", abbr: "ARE" },
      { state: "GBR. United Kingdom", abbr: "GBR" },
      { state: "USA. United States", abbr: "USA" },
      { state: "UMI. United States Minor Outlying Islands", abbr: "UMI" },
      { state: "URY. Uruguay", abbr: "URY" },
      { state: "UZB. Uzbekistan", abbr: "UZB" },
      { state: "VUT. Vanuatu", abbr: "VUT" },
      { state: "VE. Venezuela", abbr: "VE" },
      { state: "VEN. Venezuela", abbr: "VEN" },
      { state: "VNM. Viet Nam", abbr: "VNM" },
      { state: "VNM. Vietnam", abbr: "VNM" },
      { state: "VG. Virgin Islands", abbr: "VG" },
      { state: "VI. Virgin Islands", abbr: "VI" },
      { state: "WLF. Wallis and Futuna", abbr: "WLF" },
      { state: "ESH. Western Sahara", abbr: "ESH" },
      { state: "YEM. Yemen", abbr: "YEM" },
      { state: "ZMB. Zambia", abbr: "ZMB" },
      { state: "ZW. Zimbabwe", abbr: "ZWE" },
    ],
    select_fishing_arts_boat: [],
    items_select_fishing_arts_boat: [
      { nombre: "1. Sedal o de anzuelo", codigo: "UNO" },
      { nombre: "2. Nasas", codigo: "DOS" },
      { nombre: "3. Buceo", codigo: "TRES" },
    ],
    select_types_of_fishing: [],
    validity_patent: "",
    applicable_values: "",
    manager_operator: "",
    maritime_agent: "",
    expedition_by: "",
    inputBoat: true,
    location: "",
    inputBoatIT: false,
    inputBoatCP: false,
    date_issue_registration: new Date().toISOString().substr(0, 10),
    date_expiration_registration: new Date().toISOString().substr(0, 10),
    date_expedition_patent: new Date().toISOString().substr(0, 10),
    date_expiration_patent: new Date().toISOString().substr(0, 10),
    menu: false,
    menu_date_issue_registration: false,
    menu_date_expiration_registration: false,
    menuExpeditionDatePatent: false,
    menuExpirationDatePatent: false,
  }),
  directives: { money: VMoney },
  computed: {
    select_types_fishing: {
      get: function () {
        return this.select_types_of_fishing_2.length > 0
          ? this.select_types_of_fishing_2
          : this.select_types_of_fishing;
      },
      set() {
        return this.select_types_of_fishing_2.length > 0
          ? this.select_types_of_fishing_2
          : this.select_types_of_fishing;
      },
    },
    capacity_wineries: {
      get: function () {
        return this.tall_wineries * this.long_wineries * this.width_wineries;
      },
      set() {
        return this.tall_wineries * this.long_wineries * this.width_wineries;
      },
    },
    itemsTypeOfFishery(){
      return this.$store.state.variablesConcepto.items_select_types_of_fishing;
    }
  },
  methods: {
    Search() {
      this.nfound = true;
      this.owner_name = "";
      this.axios
        .get(getFisherman + "?identificacion=" + this.identification_number)
        .then((response) => {
          if (response.data.data != null) {
            this.owner_name =
              response.data.data.name + " " + response.data.data.lastname;
          } else {
            this.nfound = false;
            this.alertNotFound = true;
          }
        });
    },
    PatentV() {
      if (this.validity_patent.length <= 0) {
        this.date_expiration_patent = "";
      }
      var d = "";
      d = new Date(this.date_expedition_patent);
      var year = d.getFullYear();
      var month = d.getMonth();
      var day = d.getDate();
      var c = new Date(year, month + parseInt(this.validity_patent), day);
      this.date_expiration_patent = c.toISOString().substr(0, 10);
    },
    getStateValue(value) {
      return !value?.abbr ? value : null;
    },
    items_select_years: () => {
      let array = [];
      for (var i = new Date().getFullYear(); i >= 1800; i--) {
        array.push({ id: i, value: i });
      }

      return array;
    },
    buildRequestBody() {
      return {
        request: "U",
        _id: this.boatId,
        consecutive: this.consecutive,
        type_form: this.title,
        boat_id: this.boat_unique_id?.abbr,
        boat_unique_id_state: this.boat_number,
        registration_number: this.external_marking,
        expedition_place: this.place_issue_registration,
        expedition_by: this.expedition_by,
        expedition_date: this.date_issue_registration,
        expiration_date: this.date_expiration_registration,
        current_state_pavilion: this.select_current_state_pavilion?.abbr,
        boat_name: this.vessel_name,
        company_id: this.company_id,
        registration_port:
          this.select_registration_port?.abbr || "NOVENTAYOCHO",
        registration_port_state: this.getStateValue(
          this.select_registration_port
        ),
        fishing_port:
          this.select_authorized_landing_port?.codigo ||
          this.select_authorized_landing_port,
        boat_type: this.select_vessel_type?.abbr || "NOVENTAYOCHO",
        boat_type_state: this.getStateValue(this.select_vessel_type),
        approx_boat_value: this.value_vessel,
        types_of_fishery: this.select_types_of_fishing,
        sell_products_to: this.sell_products_to,
        length_overall: this.eslora_length,
        location: this.location,
        beam_extreme_breadth: this.extreme_breadth,
        net_registered_tonnage: this.registration_tonnage,
        calado: this.calado,
        gross_tonnage: this.registration_tonnage_gross,
        type_of_propulsion: this.select_propulsion_type?.abbr,
        main_engines_power: this.main_motor_power,
        power_unit: this.select_power_unit?.abbr || null,
        power_unit_state: this.getStateValue(this.select_power_unit),
        hull_material: this.select_helmet_material?.abbr,
        warehouses_number: this.number_wineries,
        fish_hold_high: this.tall_wineries,
        fish_hold_long: this.long_wineries,
        fish_hold_width: this.width_wineries,
        fish_hold_capacity: this.capacity_wineries,
        fish_hold_type: this.select_type_conservation_fish?.abbr || null,
        fish_hold_type_state: this.getStateValue(
          this.select_type_conservation_fish
        ),
        beneficial_owner_name: this.administrator_name,
        owner_name: this.owner_name,
        owner_identification: this.identification_number,
        owner_type_id: this.select_type_document,
        built_year: this.built_year,
        built_country: this.built_country?.abbr,
        number_of_crew: this.crew_number,
        manager_operator: this.manager_operator,
        maritime_agent: this.maritime_agent,
        number_of_fishermens: this.fishermen_number,
        fishing_arts: this.select_fishing_arts_boat,
        fishing_arts_value: this.fishing_value,
        fishing_zone: this.fishing_zone,
        fishing_line: this.select_type_line?.abbr,
        fishing_line_state: this.getStateValue(this.select_type_line),
        hook_type: this.select_type_hook?.abbr || null,
        hook_type_state: this.getStateValue(this.select_type_hook),
        hook_size: this.hook_size_type,
        hooks_number: this.hook_quantity,
        mother_line_length: this.mother_line_length,
        mother_line_material: this.select_mother_line_material?.abbr,
        mother_line_material_state: this.getStateValue(
          this.select_mother_line_material
        ),
        downpipe_material: this.select_downspout_material?.abbr || null,
        downpipe_material_state: this.getStateValue(
          this.select_downspout_material
        ),
        total_lines_number: this.total_number_lines,
        art_type_traps: this.select_denomination_art_fishing?.abbr,
        art_type_traps_state: this.getStateValue(
          this.select_denomination_art_fishing
        ),
        type_traps_number: this.cheating_amount,
        type_traps_material: this.select_main_trap_material?.abbr,
        type_traps_material_state: this.getStateValue(
          this.select_main_trap_material
        ),
        type_wound_artifacts: this.select_type_artifact?.abbr,
        type_wound_artifacts_state: this.getStateValue(
          this.select_type_artifact
        ),
        amount_artifacts: this.number_artifacts,
        artifacts_material: this.select_artifact_material?.abbr,
        artifacts_material_state: this.getStateValue(
          this.select_artifact_material
        ),
        aggregating_devices: this.radiosFishAggregatingDevice,
        type_fad: this.select_type_fad?.abbr,
        type_fad_state: this.getStateValue(this.select_type_fad),
        fad_amount: this.quantity_fad,
        fad_components: this.components_fad,
        radiosChart: this.navigation_letters,
        radiosMagneticCompass: this.magnetic_compass,
        radiosGps: this.gps,
        radiosLoran: this.loran,
        radiosRadar: this.radar,
        radiosEcosonda: this.eco_probe,
        radiosRadios: this.communication_radios,
        current_fishing_permit: this.radiosCurrentFishingPermit,
        date_expedition_patent: this.date_expedition_patent,
        date_expiration_patent: this.date_expiration_patent,
        validity_of_patent: this.validity_patent,
        applicable_values: this.applicable_values,
        type_boat: this.type.toUpperCase(),
      };
    },
    saveBoat: async function () {
      this.dialogBoat = false;
      let postData = this.buildRequestBody();
      await this.axios.post(saveBoatUrl, postData, { headers: getHeader() });
      this.$emit("successUpdate", true);
      this.$emit("handledialogBoat", false);
      this.e1 = 1;
    },
    saveBoatParcial: async function () {
      this.dialogBoat = false;
      let postData = this.buildRequestBody();
      await this.axios.post(saveBoatParcialUrl, postData, {
        headers: getHeader(),
      });
      this.$emit("successUpdate", true);
      this.$emit("handledialogBoat", false);
      this.e1 = 1;
    },
    setBoat(boat) {
      this.e1 = 1;
      if (
        this.process == "resolv" ||
        this.type_request == requestTypes.CANCELACION_PERMISO
      ) {
        this.only = true;
      }
      this.boatId = boat.id;
      (this.company_id = boat.company_id),
        (this.boat_name = boat.boat_name + " | " + boat.registration_number);
      //const default_value = { abbr: "", state: "" };
      const default_value = null;
      this.select_registration_port = default_value;
      this.select_authorized_landing_port = default_value;
      this.select_vessel_type = default_value;
      this.select_fishing_arts_boat = [];
      this.select_type_line = default_value;
      this.select_type_hook = default_value;
      this.select_mother_line_material = default_value;
      this.select_downspout_material = default_value;
      this.select_denomination_art_fishing = default_value;
      this.select_main_trap_material = default_value;
      this.select_type_artifact = default_value;
      this.select_artifact_material = default_value;
      this.select_type_fad = default_value;

      this.boat_unique_id = this.items_boat_unique_id.find(
        (x) => x.abbr == boat.boat_id
      );
      this.owner_name = boat.owner_name;
      this.identification_number = boat.owner_identification;
      if (boat.owner_type_id != null)
        this.select_type_document = this.items_select_type_document.find(
          (x) => x.abbr == boat.owner_type_id
        );
      if (this.select_type_document == undefined) {
        this.select_type_document = boat.owner_type_id;
      }
      this.location = boat.location;

      this.calado = boat.calado;
      this.expedition_by = boat.expedition_by;
      this.boat_number = boat.boat_unique_id_state;
      this.external_marking = boat.registration_number;
      this.place_issue_registration = boat.expedition_place;
      this.date_issue_registration = boat.expedition_date;
      this.date_expiration_registration = boat.expiration_date;
      if (boat.current_state_pavilion != null)
        if (
          this.items_select_current_state_pavilion.find(
            (x) => x.abbr == boat.current_state_pavilion
          ) != undefined
        )
          this.select_current_state_pavilion =
            this.items_select_current_state_pavilion.find(
              (x) => x.abbr == boat.current_state_pavilion
            );
      this.vessel_name = boat.boat_name;
      if (boat.registration_port != null) {
        if (
           this.$store.state.variablesConcepto.items_select_registration_port.find(
            (x) => x.abbr == boat.registration_port
          ) != undefined
        ) {
          this.select_registration_port =
             this.$store.state.variablesConcepto.items_select_registration_port.find(
              (x) => x.abbr == boat.registration_port
            );
        } else this.select_registration_port = boat.registration_port_state;
      }
      if (boat.fishing_port != null) {
        this.select_authorized_landing_port =
          this.$store.state.variablesConcepto.items_select_authorized_landing_port.find(
            (x) => x.codigo == boat.fishing_port
          ) || { nombre: "", codigo: "" };
        if (this.select_authorized_landing_port == undefined)
          this.select_authorized_landing_port = boat.fishing_port;
      }

      if (boat.boat_type != null)
        if (
          this.items_select_vessel_type.find((x) => x.abbr == boat.boat_type) !=
          undefined
        )
          this.select_vessel_type = this.items_select_vessel_type.find(
            (x) => x.abbr == boat.boat_type
          );
      if (boat.boat_type_state != null)
        this.select_vessel_type = boat.boat_type_state;
      this.value_vessel = boat.approx_boat_value;
      if (boat.fishing_port != null)
        if (
          this.items_select_fishing_port.find(
            (x) => x.abbr == boat.fishing_port
          ) != undefined
        )
          this.select_fishing_port = this.items_select_fishing_port.find(
            (x) => x.abbr == boat.fishing_port
          );
      this.sell_products_to = boat.sell_products_to;
      this.eslora_length = boat.length_overall;
      this.extreme_breadth = boat.beam_extreme_breadth;
      this.registration_tonnage = boat.net_registered_tonnage;
      this.registration_tonnage_gross = boat.gross_registered_tonnage;
      if (boat.type_of_propulsion != null)
        if (
          this.items_select_propulsion_type.find(
            (x) => x.abbr == boat.type_of_propulsion
          ) != undefined
        )
          this.select_propulsion_type = this.items_select_propulsion_type.find(
            (x) => x.abbr == boat.type_of_propulsion
          );
      this.main_motor_power = boat.main_engines_power;

      this.select_power_unit =
        this.items_select_power_unit.find((x) => x.abbr == boat.power_unit) ||
        boat.power_unit_state;
      if (boat.hull_material != null)
        if (
          this.items_select_helmet_material.find(
            (x) => x.abbr == boat.hull_material
          ) != undefined
        )
          this.select_helmet_material = this.items_select_helmet_material.find(
            (x) => x.abbr == boat.hull_material
          );
      this.number_wineries = boat.warehouses_number;
      this.tall_wineries = boat.fish_hold_high;
      this.long_wineries = boat.fish_hold_long;
      this.width_wineries = boat.fish_hold_width;
      this.capacity_wineries = boat.fish_hold_capacity;

      this.select_type_conservation_fish =
        this.items_select_type_conservation_fish.find(
          (x) => x.abbr == boat.fish_hold_type
        ) || boat.fish_hold_type_state;

      if (boat.construction_country != null)
        if (
          this.items_select_current_state_pavilion.find(
            (x) => x.abbr == boat.construction_country
          ) != undefined
        )
          this.built_country = this.items_select_current_state_pavilion.find(
            (x) => x.abbr == boat.construction_country
          );
      this.administrator_name = boat.beneficial_owner_name;
      this.built_year =
        boat.year_of_construction != null
          ? JSON.parse(boat.year_of_construction)
          : boat.year_of_construction;
      this.crew_number = boat.number_of_crew;
      this.manager_operator = boat.manager_operator;
      this.maritime_agent = boat.maritime_agent;
      this.fishermen_number = boat.number_of_fishermens;
      if (boat.fishing_arts.length > 0)
        this.select_fishing_arts_boat = boat.fishing_arts;

      this.fishing_value = boat.fishing_arts_value;
      this.fishing_zone = boat.fishing_zone;
      if (boat.fishing_line != null)
        if (
          this.items_select_type_line.find(
            (x) => x.abbr == boat.fishing_line
          ) != undefined
        )
          this.select_type_line = this.items_select_type_line.find(
            (x) => x.abbr == boat.fishing_line
          );

      if (
        boat.fishing_line_state != null &&
        boat.fishing_line_state != '{"abbr":null,"state":null}'
      )
        this.select_type_line = boat.fishing_line_state;
      if (boat.hook_type != null)
        if (
          this.items_select_type_hook.find((x) => x.abbr == boat.hook_type) !=
          undefined
        )
          this.select_type_hook = this.items_select_type_hook.find(
            (x) => x.abbr == boat.hook_type
          );
      if (boat.hook_type_state != null)
        this.select_type_hook = boat.hook_type_state;
      this.hook_size_type = boat.hook_size;
      this.hook_quantity = boat.hooks_number;
      this.mother_line_length = boat.mother_line_length;
      if (boat.mother_line_material != null)
        if (
          this.items_select_mother_line_material.find(
            (x) => x.abbr == boat.mother_line_material
          ) != undefined
        )
          this.select_mother_line_material =
            this.items_select_mother_line_material.find(
              (x) => x.abbr == boat.mother_line_material
            );
      if (boat.mother_line_material_state != null)
        this.select_mother_line_material = boat.mother_line_material_state;
      if (boat.downpipe_material != null)
        if (
          this.items_select_downspout_material.find(
            (x) => x.abbr == boat.downpipe_material
          ) != undefined
        )
          this.select_downspout_material =
            this.items_select_downspout_material.find(
              (x) => x.abbr == boat.downpipe_material
            );
      if (boat.downpipe_material_state != null)
        this.select_downspout_material = boat.downpipe_material_state;
      this.total_number_lines = boat.total_lines_number;
      if (boat.art_type_traps != null)
        if (
          this.items_select_denomination_art_fishing.find(
            (x) => x.abbr == boat.art_type_traps
          ) != undefined
        )
          this.select_denomination_art_fishing =
            this.items_select_denomination_art_fishing.find(
              (x) => x.abbr == boat.art_type_traps
            );
      if (boat.art_type_traps_state != null)
        this.select_denomination_art_fishing = boat.art_type_traps_state;
      this.cheating_amount = boat.type_traps_number;
      if (boat.type_traps_material != null)
        if (
          this.items_select_main_trap_material.find(
            (x) => x.abbr == boat.type_traps_material
          ) != undefined
        )
          this.select_main_trap_material =
            this.items_select_main_trap_material.find(
              (x) => x.abbr == boat.type_traps_material
            );
      if (boat.type_traps_material_state != null)
        this.select_main_trap_material = boat.type_traps_material_state;
      if (boat.type_wound_artifacts != null)
        if (
          this.items_select_type_artifact.find(
            (x) => x.abbr == boat.type_wound_artifacts
          ) != undefined
        )
          this.select_type_artifact = this.items_select_type_artifact.find(
            (x) => x.abbr == boat.type_wound_artifacts
          );
      if (boat.type_wound_artifacts_state != null)
        this.select_type_artifact = boat.type_wound_artifacts_state;
      this.number_artifacts = boat.amount_artifacts;
      if (boat.artifacts_material != null)
        if (
          this.items_select_artifact_material.find(
            (x) => x.abbr == boat.artifacts_material
          ) != undefined
        )
          this.select_artifact_material =
            this.items_select_artifact_material.find(
              (x) => x.abbr == boat.artifacts_material
            );
      if (boat.artifacts_material_state != null)
        this.select_artifact_material = boat.artifacts_material_state;
      this.radiosFishAggregatingDevice =
        boat.aggregating_devices == 1
          ? "SI"
          : boat.aggregating_devices == 0
          ? "NO"
          : "";
      if (boat.type_fad != null)
        if (
          this.items_select_type_fad.find((x) => x.abbr == boat.type_fad) !=
          undefined
        )
          this.select_type_fad = this.items_select_type_fad.find(
            (x) => x.abbr == boat.type_fad
          );
      if (boat.type_fad_state != null)
        this.select_type_fad = boat.type_fad_state;
      this.quantity_fad = boat.fad_amount;
      this.components_fad = boat.fad_components;
      this.radiosChart =
        boat.navigation_letters == 1
          ? "SI"
          : boat.navigation_letters == 0
          ? "NO"
          : boat.navigation_letters != null
          ? boat.navigation_letters
          : "";
      this.magnetic_compass = boat.radiosMagneticCompass;
      this.radiosGps =
        boat.gps == 1
          ? "SI"
          : boat.gps == 0
          ? "NO"
          : boat.gps != null
          ? boat.gps
          : "";
      this.radiosLoran =
        boat.loran == 1
          ? "SI"
          : boat.loran == 0
          ? "NO"
          : boat.loran != null
          ? boat.loran
          : "";
      this.radiosRadar =
        boat.radar == 1
          ? "SI"
          : boat.radar == 0
          ? "NO"
          : boat.radar != null
          ? boat.radar
          : "";
      this.radiosEcosonda =
        boat.eco_probe == 1
          ? "SI"
          : boat.eco_probe == 0
          ? "NO"
          : boat.eco_probe != null
          ? boat.eco_probe
          : "";
      this.radiosRadios =
        boat.communication_radios == 1
          ? "SI"
          : boat.communication_radios == 0
          ? "NO"
          : boat.communication_radios != null
          ? boat.communication_radios
          : "";
      this.radiosCurrentFishingPermit =
        boat.current_fishing_permit == 1
          ? "SI"
          : boat.current_fishing_permit == 0
          ? "NO"
          : boat.current_fishing_permit != null
          ? boat.current_fishing_permit
          : "";
      this.date_expedition_patent = boat.date_expedition_patent;
      this.date_expiration_patent = boat.date_expiration_patent;
      this.validity_patent = boat.validity_of_patent;
      this.applicable_values = boat.applicable_values;
      this.select_types_of_fishing = boat.type_fishery;
    },
  },
};
</script>
|| []